<template>
  <div data-test="mini-cart">
    <div class="section-body text-left">
      <div class="items">
        <div class="item-list">
          <div
            v-for="(item, index) of items"
            :key="item.id"
            class="item px-5 py-4"
            :class="{ 'border-bottom': index < items.length - 1 }"
          >
            <div class="row">
              <div
                class="headline"
                :class="itemHasLargeAmount(item)
                  ? [ 'col-6', 'col-md-8' ]
                  : [ 'col-7', 'col-md-9' ]"
              >{{ item.payable.displayName }}</div>
              <AmountInput
                :cart-item="item"
                class="col-5 col-md-3 text-right cart-amount-small"
                :class="itemHasLargeAmount(item)
                  ? [ 'col-6', 'col-md-4' ]
                  : [ 'col-5', 'col-md-3' ]"
                :read-only="true"
              />
            </div>
            <div class="row d-flex align-items-center">
              <div class="col d-flex align-items-center">
                <QuantityInput
                  v-if="item.canModifyQuantityDirectly && !cart.locked"
                  :cart-item="item"
                  small
                  @quantity-update="$emit('quantityUpdate')"
                  @payable-removed="$emit('payableRemoved')"
                />
                <RemoveItemLink
                  v-if="showItemRemoveLink(item) && !cart.locked"
                  :cart-item="item"
                  @removed="$emit('payableRemoved')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="border-top border-gray px-5 py-3">
        <div class="row">
          <div class="col-6 col-sm-9 d-flex justify-content-sm-end align-items-center">
            {{ $t("subtotal.label") }}
          </div>
          <div
            class="col-6 col-sm-3 d-flex justify-content-end align-items-center cart-amount-small"
            data-test="subtotal"
          >
            $ {{ displayFormat(cart.subtotal) }}
          </div>
        </div>
      </div>

      <!-- XXX: In the cart demo app the css for a.btn-primary is broken -->
      <a
        href="#"
        class="btn btn-primary mx-5 mb-5 d-block"
        data-test="check-out-button"
        @click="goToCheckout"
      >
        {{ $t("goto.checkout") }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { displayFormat } from '@grantstreet/psc-js/utils/numbers.js'
import QuantityInput from './QuantityInput.vue'
import RemoveItemLink from './RemoveItemLink.vue'
import AmountInput from './AmountInput.vue'
import { showItemRemoveLink, itemHasLargeAmount } from '../store/helpers.js'

export default {
  emits: ['quantityUpdate', 'payableRemoved', 'continue'],
  components: {
    QuantityInput,
    RemoveItemLink,
    AmountInput,
  },

  computed: {
    ...mapGetters('Cart', [
      'cart',
      'items',
    ]),
  },

  methods: {
    displayFormat,

    showItemRemoveLink,

    itemHasLargeAmount,

    goToCheckout (event) {
      // This is important. We've been navigating twice in some weird way where
      // the navigation caused by this event happens first, followed by adding a
      // # from clicking the link. I always thought it was odd that we sometimes
      // ended up on /checkout# but never put it together. (I think this might
      // even messed with the history stack at some point.)
      //
      // It's now important that we fix this because there's some mysterious
      // interplay between something and Testcafe that causes navigation to
      // break when this happens.
      //
      // Tl;dr: When clicking this link the vue-router navigation happens, then
      // a split second later something simulates the href="#" but using the old
      // window.location. I didn't quite track it down in the minified source
      // but I did a fair bit of perusing. If you want to, it's not thaaaat
      // impenetrable. - vs 2021-10-11
      event.preventDefault()
      this.$emit('continue')
    },
  },
}
</script>

<style lang="scss" scoped>

.item-list {
  max-height: 270px;
  overflow: auto;
}

.headline {
  font-weight: bold;
  font-size: 1.125rem;
}

</style>
