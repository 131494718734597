import store, {
  ConfigGetterKey,
  ConfigGetter,
  ConfigStateKey,
  ConfigState,
  ConfigMutationKey,
  ConfigMutation,
  ConfigActionKey,
  ConfigAction,
  InstallParams,
} from './store/index.ts'

// --- Installer

export const installConfig = (params: InstallParams) => store.dispatch('initialize', params)

// --- Methods

export const setAdminScope = scope => store.commit('setAdminScope', scope)
export const setLDMetadata = user => store.commit('setLDMetadata', user)
export const setAdminUserJwt = jwt => store.commit('setAdminUserJwt', jwt)
export const setNonProdJwt = jwt => store.commit('setNonProdJwt', jwt)
export const setProdJwt = jwt => store.commit('setProdJwt', jwt)
export const setClientList = clients => store.commit('setClientList', clients)
export const addClientLocal = client => store.commit('addClientLocal', client)
export const loadConfig = ({ client, site }: { client?: string, site?: string }) => store.dispatch('loadConfig', { client, site })

export const populateClientSettings = clientId => store.dispatch('populateClientSettings', clientId)
export const getSiteSetting = ({
  api,
  site,
  module,
  setting,
}) => store.dispatch('getSiteSetting', {
  api,
  site,
  module,
  setting,
})
export const loadFlags = () => store.dispatch('loadFlags')
export const loadAllConfigs = () => store.dispatch('loadAllConfigs')
export const loadAdminConfig = () => store.dispatch('loadAdminConfig')
export const loadAdminModule = (...args) => store.dispatch('loadAdminModule', ...args)
export const computeClientSiteLists = () => store.dispatch('computeClientSiteLists')
export const getClientsAndSites = () => store.dispatch('getClientsAndSites')
export const loadConfigGlobals = () => store.dispatch('loadConfigGlobals')
export const getAllNonProdSettings = () => store.dispatch('getAllNonProdSettings')
export const getAllProdSettings = () => store.dispatch('getAllProdSettings')
export const populateAllMetadata = () => store.dispatch('populateAllMetadata')

export const configState = store.state
export const configGetters = store.getters

type Mapper<K extends string, V> = (keys: K[]) => Partial<Record<K, () => V>>
const makeMapper = makeCallback => keys =>
  Object.fromEntries(keys.map(key => [key, makeCallback(key)]))

// Vuex like helpers that let you add state/getters to the computed property and
// actions/mutation to the methods property of a vue component that uses the
// options api with typescript powered property autocomplete (only works if the
// script element has lang="ts").
export const mapConfigState: Mapper<ConfigStateKey, ConfigState> = makeMapper(key => () => store.state[key])
export const mapConfigGetters: Mapper<ConfigGetterKey, ConfigGetter> = makeMapper(key => () => store.getters[key])
export const mapConfigMutations: Mapper<ConfigMutationKey, ConfigMutation> = makeMapper(key => (...args) => store.commit(key, ...args))
export const mapConfigActions: Mapper<ConfigActionKey, ConfigAction> = makeMapper(key => (...args) => store.dispatch(key, ...args))

export { settingValuesAreSubset, generateClientList } from './utils.ts'

// --- Models

export { default as Client } from './models/Client.ts'
export { default as Site } from './models/Site.ts'
