export function canUseApplePay () {
  try {
    // canMakePayments throws an exception when called from a cross-origin
    // iframe, or when not explicitly allowed via Feature-Policy. (And possibly
    // in other unknown circumstances.)
    return window.ApplePaySession?.canMakePayments?.()
  }
  catch (error) {
    // TODO PSC-13809 For now we expect this to break in the Checkout Widget,
    // which uses cross-origin iframes, so it's not worth reporting to Sentry.
    // Once we have Apple Pay working in the Checkout Widget, log this kind of
    // error to Sentry. (Sentry isn't imported in psc-js, it'll need to be
    // passed in.)
    console.error('Apple Pay unavailable: ', error)
  }

  return false
}
