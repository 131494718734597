import capitalize from 'lodash/capitalize.js'
import startCase from 'lodash/startCase.js'
import { i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import { normalizeCardBrand } from '../card-brands.js'
import { countryCodesWithoutPostalCodes } from '@grantstreet/psc-vue/utils/country-codes.js'

// These objects map tender object properties to database columns. They are used
// to convert tender objects to objects that can be sent to the microservice to
// store in the DB or to parse DB objects.
const tenderPropsToCols = {
  tenderId: 'tender_id',
  userId: 'user_id',
  ewalletToken: 'ewallet_token',
  warehouseToken: 'warehouse_token',
  type: 'type',
  lastDigits: 'last_digits',
  isLastUsed: 'is_last_used',
  nickname: 'nickname',
  userName: 'user_name',
  displayInWallet: 'display_in_wallet',

  cardExpirationMonth: 'card_expiration_month',
  cardExpirationYear: 'card_expiration_year',
  cardBrand: 'card_brand',
  savedPaymentMethodDisclosure: 'card_on_file_disclosure',

  bankAccountType: 'bank_account_type',
  bankRoutingNumber: 'bank_routing_number',
  // Not actually a DB col, but it needs to be passed to the server:
  bankAccountNumber: 'bank_account_number',
  bankName: 'bank_name',
  isExpiring: 'is_expiring',
  // PayPal Email isn't a DB col, but needs to be passed for checkout for tokenized PayPal
  paypalEmail: 'paypal_email',
}
const addressPropsToCols = {
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  state: 'state',
  postalCode: 'postal_code',
  country: 'country',
}

// Constructs a new tender based on the passed database data. This mainly just
// converts the snake_case columns to camelCase using the above objects. See
// also tender.toDBFormat().
// source represents where the underlying tender came from,
// eg. "apple" can be the source of card info.
// The backend does not store this information,
// and it will be "unknown" if not specified
export function tenderFromDBFormat (backendData, source = 'unknown') {
  const tender = { source }

  // Copy main cols
  for (const [prop, col] of Object.entries(tenderPropsToCols)) {
    if (backendData.hasOwnProperty(col)) {
      tender[prop] = backendData[col]
    }
  }

  // Copy billing address cols
  if (backendData.billing_address) {
    tender.billingAddress = {}
    for (const [prop, col] of Object.entries(addressPropsToCols)) {
      if (backendData.billing_address.hasOwnProperty(col)) {
        tender.billingAddress[prop] = backendData.billing_address[col]
      }
    }
  }

  // Special case
  if (backendData.type === 'bank') {
    tender.bankAccountCategory = backendData.bank_account_is_corporate ? 'commercial' : 'personal'
  }

  return new Tender(tender)
}

export default class Tender {
  // Constructs a new tender. This expects each attribute to be in camelCase. If
  // you want to construct a Tender object based on a tender retrieved from the
  // DB (which uses snake_case), use the tenderFromDBFormat() helper function.
  constructor (data, needsBillingAddress) {
    if (!data) {
      console.error('Error: must pass data to tender constructor')
      return
    }

    // Validate the required fields
    // PayPal does not require a username for their tender
    const requiredFields = ['type', ...(data.type === 'paypal' ? [] : ['userName'])]
    for (const field of requiredFields) {
      if (!data[field]) {
        console.error(`Error: tender field "${field}" is required`)
        return
      }
    }
    if (needsBillingAddress) {
      if (!data.billingAddress) {
        console.error('Error: must pass billing address with tender')
        return
      }
      const requiredBilling = [
        'address1',
        'city',
        'state',
        ...(countryCodesWithoutPostalCodes.includes(data.billingAddress.country) ? [] : ['postalCode']),
        'country',
      ]
      for (const field of requiredBilling) {
        if (!data.billingAddress[field]) {
          console.error(`Error: tender billing address field "${field}" is required`)
          return
        }
      }
    }

    // Set the expiration, month, and year based on provided attributes
    if (data.cardExpiration && (!data.cardExpirationMonth || !data.cardExpirationYear)) {
      const match = data.cardExpiration.match(/^(\d{1,2})\/(\d{2})/)
      if (match) {
        data.cardExpirationMonth = match[1]
        data.cardExpirationYear = match[2]
      }
      else {
        // The expiration is malformed. This shouldn't happen.
        // This is a better way to remove props than the delete operator.
        // See psc-js/utils/objects.js for more.
        const { cardExpiration: deleted, ...sanitized } = data
        data = sanitized
      }
    }
    else if (!data.cardExpiration && data.cardExpirationMonth && data.cardExpirationYear) {
      if (data.cardExpirationMonth.length === 1) {
        data.cardExpirationMonth = '0' + data.cardExpirationMonth
      }
      data.cardExpiration = data.cardExpirationMonth + '/' + data.cardExpirationYear
    }

    // Set the lastDigits for banks -- forcibly overriding what's there,
    // if the bankAccountNumber is present
    if (data.type === 'bank' && data.bankAccountNumber) {
      const length = data.bankAccountNumber.length
      if (length >= 3) {
        const numDigits =
          length === 3 ? 1
          : length < 8 ? 2
          : 4
        data.lastDigits = data.bankAccountNumber.slice(-numDigits)
      }
    }

    // Populate the new tender with the passed data
    Object.assign(this, data)
  }

  // Returns this tender with its attribute in snake_case so it can be saved to
  // the database.
  toDBFormat () {
    const dbRow = {}

    // Copy main props
    // The this[prop] there is for the "getter" for bankAccountNumber
    for (const [prop, col] of Object.entries(tenderPropsToCols)) {
      if (this.hasOwnProperty(prop)) {
        dbRow[col] = this[prop]
      }
    }

    // Copy billing address props
    if (this.billingAddress) {
      /* eslint-disable camelcase */
      dbRow.billing_address = {}
      for (const [prop, col] of Object.entries(addressPropsToCols)) {
        if (this.billingAddress.hasOwnProperty(prop)) {
          dbRow.billing_address[col] = this.billingAddress[prop]
        }
      }
    }

    if (this.type === 'bank') {
      dbRow.bank_account_is_corporate = this.bankAccountCategory === 'commercial'
    }

    return dbRow
  }

  // TODO: PSC-4534 Localize
  get description () {
    let description
    if (this.isCard) {
      description = this.capitalizedType
    }
    else if (this.type === 'bank') {
      description = this.bankName
        ? this.niceBankName
        : this.capitalizedType + ' Account'
    }
    else {
      throw new TypeError(`Unknown tender type: ${this.type}`)
    }

    if (this.lastDigits) {
      // eslint-disable-next-line camelcase
      description += ' ' + i18n.global.t('card.ending_in', { last_digits: this.lastDigits })
    }
    return description
  }

  get name () {
    if (this.nickname) {
      return this.nickname
    }
    else if (this.isCard) {
      return `${this.capitalizedType} Card`
      // e.g. Visa Card
    }
    else if (this.isBank) {
      return `${this.capitalizedType} Account`
      // e.g. Checking Account
    }
    else if (this.isPayPal) {
      return 'PayPal'
    }
  }

  get capitalizedType () {
    if (this.isCard) {
      return normalizeCardBrand(this.cardBrand)
    }
    else if (this.isBank) {
      return capitalize(this.bankAccountType)
    }
    else if (this.isPayPal) {
      return 'PayPal'
    }
  }

  get niceBankName () {
    if (!this.bankName) {
      return ''
    }
    return startCase(this.bankName.toLowerCase())
  }

  get isCard () {
    return this.type === 'card'
  }

  get isBank () {
    return this.type === 'bank'
  }

  get isPayPal () {
    return this.type === 'paypal'
  }

  get cardExpirationMMYY () {
    return this.cardExpirationMonth + '/' + this.cardExpirationYear.slice(2)
  }

  // Allow passing the "current" date for easier unit-testing
  isExpired (curDate) {
    if (!this.cardExpirationMonth || !this.cardExpirationYear) {
      return false
    }

    const cardExpiration = {
      mm: this.cardExpirationMonth,
      yy: this.cardExpirationYear,
    }

    const now = {
      mm: curDate.getMonth() + 1,
      yy: curDate.getFullYear(),
    }

    if (now.yy > cardExpiration.yy) {
      return true
    }

    if (now.yy === cardExpiration.yy && now.mm > cardExpiration.mm) {
      return true
    }

    return false
  }

  get expired () {
    return this.isExpired(new Date())
  }

  // Returns a string of "$type ending in $digits". Accepts a translated
  // "endinig in" string.
  typeWithLastDigits (endingInStr) {
    if (this.isBank || this.isCard) {
      return `${this.capitalizedType} ${endingInStr} ${this.lastDigits}`
    }
    else {
      return this.capitalizedType
    }
  }
}
