<template>
  <div
    :class="{
      'no-top-nav': !hasHeader,
      'border-top': hasHeader && !noTopBorder,
      'bg-light': !noBackground,
      'gh-page-padding': !noPadding,
      'mb-3': !noMargin,
    }"
    class="nav-steps nav-aware-top sticky-top d-print-none border-bottom"
  >

    <!-- Make sure the padding and gh-container classes match those on the page container -->
    <div class="gh-container d-flex flex-wrap justify-content-center justify-content-md-start gh-page-padding">
      <div
        v-for="(step, idx) in navSteps"
        :key="idx"
        class="d-flex align-items-center"
      >
        <div
          :class="{ current: isCurrentStep(idx) && !allStepsCompleted }"
          class="nav-step d-flex align-items-center py-2"
          :aria-label="$t(step.title)"
          data-test="nav-step"
        >
          <ProgressBarNumberIcon
            v-if="useNumberIcons"
            :number="idx + 1"
            :completed="isStepCompleted(idx)"
          />
          <svgicon
            v-else
            :icon="isStepCompleted(idx) ? 'progress-bar-checkmark' : step.icon"
            :class="{ completed: isStepCompleted(idx) }"
            :fill="isStepCompleted(idx)"
            class="mr-0 mr-md-2 thin"
            height="2rem"
            width="2rem"
          />
          <div
            :class="{
              'ml-0': useNumberIcons,
              'ml-md-2': useNumberIcons,
            }"
            class="d-none d-md-block"
          >
            {{ $t(step.title) }}
          </div>
        </div>
        <svgicon
          v-if="idx !== navSteps.length - 1"
          icon="caret"
          class="mx-2 mx-md-4 thin-caret"
          dir="right"
          height=".75rem"
          width="1.5rem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ProgressBarNumberIcon from '@grantstreet/psc-vue/components/ProgressBarNumberIcon.vue'

export default {
  components: {
    ProgressBarNumberIcon,
  },

  props: {
    currentStep: {
      type: String,
      default: '',
    },
    useNumberIcons: {
      type: Boolean,
      default: false,
    },
    noTopBorder: {
      type: Boolean,
      default: false,
    },
    noBackground: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    allStepsCompleted: {
      type: Boolean,
      default: false,
    },
    steps: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    hasHeader () {
      return !this.$route.meta.hideHeader && this.enableHeader
    },
    navSteps () {
      return this.steps.length > 0 ? this.steps : this.checkoutNavSteps
    },

    currentStepIdx () {
      const steps = this.navSteps
      return steps.indexOf(steps.find(s => s.name === this.currentStep))
    },
    ...mapState('PayHub', ['enableHeader']),
    ...mapGetters('Cart', ['checkoutNavSteps']),
  },

  methods: {
    isStepCompleted (stepIdx) {
      return stepIdx < this.currentStepIdx || this.allStepsCompleted
    },

    isCurrentStep (stepIdx) {
      return stepIdx === this.currentStepIdx
    },
  },
}
</script>

<style lang="scss" scoped>
.nav {
  &-step {
    border: 5px solid transparent;
    border-width: 4px 0;
    transition: .3s all ease-out;
  }

  &-steps {
    // The lightest ADA-compliant color:
    left: 0;
    right: 0;
    width: 100%;
    color: #707070;
    z-index: $zindex-sticky;

    .current {
      border-top-color: transparent;
      border-bottom: 4px solid darken($border-color, 3);
      color: black;
      font-weight: 700;
    }
  }
}

.svg-icon.completed {
  fill: $success;
  stroke: white;
}
</style>
