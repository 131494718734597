import Vue from 'vue'
import PayHub from '@grantstreet/govhub-vue'
import { sentryException } from '@grantstreet/govhub-vue/src/sentry.js'

// IIFE so we can have an async context
;(async () => {
  // Install PayHub
  // Don't use Vue.use, it's sync, doesn't look at return values, and there's
  // no benefit to the syntactic sugar.
  const ok = await PayHub(Vue)

  // You can check the status of the installer and continue/return
  if (!ok && ok !== null) {
    const error = new Error('Error installing PayHub')
    console.error(error)
    // Sentry initialization is the first step of the installer so this will
    // only fail if sentry is incapable of sending messages
    sentryException(error)
  }
  // An explicit return would go here if there were anything left to abort
})()
