import ParentIframeWhisperer from './iframe-whisperer/parent.js'
import ChildIframeWhisperer from './iframe-whisperer/child.js'

export const isInIframe = () => window.location !== window.parent.location

export {
  default as ParentIframeWhisperer,
} from './iframe-whisperer/parent.js'

export {
  default as ChildIframeWhisperer,
} from './iframe-whisperer/child.js'

export default (...args) => isInIframe() ? new ParentIframeWhisperer(...args) : new ChildIframeWhisperer(...args)
