<template>
  <!-- log in button -->
  <LoginButton
    v-if="!loggedIn"
    class="p-0 no-mw bg-white"
    data-test="top-nav-login"
    icon
    :login-function="loginFunction"
    @show-login-disabled-modal="$emit('showLoginDisabledModal')"
  />

  <!-- profile/log out button -->
  <b-dropdown
    v-else
    variant="link"
    class="dd-z-index"
    toggle-class="p-0 bg-white"
    size="sm"
    data-test="top-nav-user"
    no-caret
    @show="ddActive = true"
    @hide="ddActive = false"
  >
    <template #button-content>
      <b-link
        class="d-flex align-items-center m-1 logged-in-icon"
        :aria-label="$t('topnav.myaccount')"
      >
        <svgicon
          fill
          icon="login-icon-loggedin"
          width="1rem"
          height="1rem"
        />
        <svgicon
          :fill="false"
          name="caret"
          color="#555555"
          class="mr-2 ml-1"
          :dir="ddActive ? 'up' : 'down'"
          height="0.5rem"
          width="0.5rem"
        />
      </b-link>
    </template>
    <b-dropdown-item
      v-for="{text, icon, props, on} of dropdownItems"
      :key="text"
      :data-test="text"
      v-bind="props"
      v-on="on"
    >
      <svgicon
        v-if="icon"
        :fill="false"
        :name="icon"
      />
      {{ text }}
    </b-dropdown-item>
    <b-dropdown-item
      variant="primary-link"
      data-test="logout-link"
      @click="logout"
    >
      <svgicon
        :fill="false"
        name="log-out"
      />
      {{ $t('topnav.logout') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import LoginButton from '@grantstreet/login/src/components/LoginButton.vue'
import { loginManager } from '@grantstreet/login'
import '@grantstreet/bootstrap/icons/js/login-icon-loggedin.js'

export default {
  emits: ['showLoginDisabledModal'],
  components: {
    LoginButton,
  },

  props: {
    loggedIn: {
      type: Boolean,
      required: true,
    },
    logoutRedirectUrl: {
      type: String,
      default: null,
    },
    dropdownItems: {
      type: Array,
      default: () => [],
    },
    loginFunction: {
      type: Function,
      default: undefined,
    },
  },

  data: () => ({
    ddActive: false,
  }),

  methods: {
    logout () {
      loginManager.logout(this.logoutRedirectUrl)
    },
  },
}
</script>

<style lang="scss" scoped>
.dd-z-index {
  // This should really be $zindex-dropdown, but that's lower than
  // $zindex-sticky, which is what the nav steps use. We need the dropdown to be
  // above the nav steps.
  z-index: $zindex-fixed;
}

.no-mw,
::v-deep .no-mw {
  min-width: unset;
}
.border-subtle,
::v-deep .border-subtle {
  border-color: $off-white !important;
}

.logged-in-icon {
  background-color: #0077B315;
  border-radius: 1em;
}

::v-deep .dropdown-item {
  padding: 1rem;
}
</style>
