const ETIMEOUT = new Error('Operation Timed Out')

export const withTimeout = function (timeout, promise) {
  return Promise.race([
    promise,
    new Promise((resolve, reject) => {
      setTimeout(() => reject(ETIMEOUT), timeout)
    }),
  ])
}
export const isTimeout = (result) => result === ETIMEOUT

export default withTimeout
