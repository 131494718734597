//
// Client for the Login Service.
//
import GenericApiClient from '@grantstreet/api-client'
import { baseGovHubUrl } from '@grantstreet/psc-environment'
import queryString from 'query-string'

export default class Client extends GenericApiClient {
  constructor (opts) {
    super(opts)

    this.baseUrl = () => process.env?.GSG_LOGIN_SERVICE || `${baseGovHubUrl}/svc/login`
  }

  // Auth is optional, but verified if provided
  ping () {
    return this.get('/v1/ping')
  }

  createUser (user = {}) {
    return this.post('/v1/users', user)
  }

  getUser (uid) {
    return this.get(`/v1/users/${uid}`)
  }

  findUsersByEmail (email) {
    return this.get('/v1/findUser?email=' + encodeURIComponent(email))
  }

  // Supports partial updates. Also: supports bothr
  // admin and end-user JWTs.
  updateUser (uid, profile) {
    return this.patch(`/v1/users/${uid}`, profile)
  }

  deleteUser (uid) {
    return this.delete(`/v1/users/${uid}`)
  }

  updateUserMetadata (metadata = {}) {
    return this.post('/v1/public/update_user_metadata', metadata)
  }

  // No auth required
  sendVerificationMail (email) {
    return this.post('/v1/sendVerificationMail', { email })
  }

  exchangeToken ({
    grantType,
    clientId,
    audience,
    scope,
    subjectToken,
    subjectTokenType,
    requestedTokenType,
  }) {
    return this.post('/oidc/token', queryString.stringify({
      /* eslint-disable camelcase */
      grant_type: grantType,
      client_id: clientId,
      audience,
      scope,
      subject_token: subjectToken,
      subject_token_type: subjectTokenType,
      requested_token_type: requestedTokenType,
      /* eslint-enable camelcase */
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  }
}
