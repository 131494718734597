<template>
  <b-link
    v-if="icon"
    class="position-relative cursor-pointer"
    :aria-label="$t('topnav.login')"
    @click="loginWithProgress({ callbackAction })"
  >
    <svgicon
      :class="signInProgress ? 'text-muted': ''"
      icon="login-icon-guest"
      width="1rem"
      height="1rem"
    />
  </b-link>

  <ProgressButton
    v-else
    data-test="login-button"
    :size="size"
    :waiting="signInProgress"
    :disabled="signInProgress"
    :variant="variant"
    :inline="true"
    @click="loginWithProgress({ callbackAction })"
  >
    <slot />
  </ProgressButton>
</template>

<script>
import { loginManager } from '../install.ts'
import ProgressButton from '@grantstreet/psc-vue/components/ProgressButton.vue'
import { withTimeout, isTimeout } from '@grantstreet/psc-js/utils/timeout.js'
import { sentryException } from '../sentry.js'
import '@grantstreet/bootstrap/icons/js/login-icon-guest.js'

export default {
  emits: ['showLoginDisabledModal'],
  components: {
    ProgressButton,
  },

  props: {
    icon: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    callbackAction: {
      type: Object,
      default: () => null,
    },
    loginFunction: {
      type: Function,
      default: ({ callbackAction }) => loginManager.login({ callbackAction }),
    },
  },

  data () {
    return {
      signInProgress: false,
    }
  },

  methods: {
    async loginWithProgress ({ callbackAction }) {
      if (!this.signInProgress) {
        this.signInProgress = true

        try {
          await withTimeout(
            30 * 1000,
            new Promise(() => this.loginFunction({ callbackAction })),
          )
        }
        catch (error) {
          this.signInProgress = false
          this.$emit('showLoginDisabledModal')

          if (isTimeout(error)) {
            sentryException('Reaching the Login Service timed out.', {
              level: 'warning',
            })
            return
          }

          throw error
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  min-width: 13.5rem;
}
</style>
