import paymentsStore from './store.js'
import { loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'

export default function install (Vue, { store, api, sentryException }) {
  store.commit('API/setMyPaymentsApi', api)
  if (!store.state.MyPayments) {
    store.registerModule('MyPayments', paymentsStore)
  }

  loadTranslations(sentryException)
}
