// This plugin installs the Cart & Checkout component into your Vue app.
//
// Usage:
//
//   import Cart from '@grantstreet/cart-vue'
//   import VueSVGIcon from @grantstreet/bootstrap/icons/vue-svgicon'
//
//   // Install prerequisites
//   Vue.use(VueSVGIcon)
//
//   // Install Cart
//   Vue.use(Cart)
import cartStore from './store/index.js'
import Cart from './components/Cart.vue'
import CartIconPopper from './components/CartIconPopper.vue'
import './styles/cart.scss'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'
import { loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'
import VueGtag from 'vue-gtag'
import { getPayHubGaId } from '@grantstreet/psc-vue/utils/google-analytics.js'
import { sentryException } from './sentry.js'
export { Cart, CartIconPopper }
export default function install (Vue, {
  store,
  wait,
  bus,
  api,
  cartId,
}) {
  store.commit('API/setCartApi', api)
  Vue.use(VueDOMPurifyHTML, sanitizeConfig)
  Vue.component('cart', Cart)
  // TODO: Is there still any reason to globally install this? Typically we
  // avoid that pattern in favor of local imports
  Vue.component('cart-icon-popper', CartIconPopper)
  // Register the Cart Vuex store
  if (!store.state.cart) {
    store.registerModule('Cart', cartStore)
  }
  loadTranslations(sentryException)
  // ---------------------------------------------------------------------------
  // Install plugins that weren't already installed by the parent app
  if (!Vue.$gtag) {
    Vue.use(VueGtag, {
      config: { id: getPayHubGaId() },
      // Don't report anything in sandboxes
      disableScriptLoad: process.env.NODE_ENV === 'development',
    })
  }
  // This is the initialization promise
  store.commit('Cart/setCartLoadPromise', loadCart({ store, wait, cartId, bus }))
  // This event runs after the user has signed up or signed in
  // via the ad on the PayHub receipt page. We'll have stashed
  // the tender id that they paid with and their anonymous
  // "user id" in E-Wallet, and now we can attach the tender to
  // their authenticated user account.
  //
  // It's strange that *cart* has to process the event. But cart
  // is responsible for initializing the E-Wallet session, and
  // we need to do that in order to claim the tender.
  bus.$once('login.callbackAction', async ({ user, action }) => {
    if (user && action.type === 'claimTender') {
      await store.getters['Cart/cartLoadPromise']
      await store.getters['eWallet/loadPromise']
      const { tenderId, userId, disclosureText } = action
      const api = store.getters['API/ewallet']
      api.claimTender(tenderId, userId, disclosureText)
    }
    if (user && action.type === 'enrollInAutoPay') {
      await store.getters['Cart/cartLoadPromise']
      const { payablePath } = action
      store.commit('Cart/enrollPayableInAutoPay', payablePath)
    }
    if (user && action.type === 'autoEnrollRenewals') {
      await store.getters['Cart/cartLoadPromise']
      store.commit('Cart/autoenrollRenewalsInAutopay', true)
    }
  })
}
export async function loadCart ({
  store,
  wait,
  cartId,
  bus,
  create = true,
  merge = true,
} = {}) {
  wait.start('loading cart')
  try {
    // Load cart as soon as the user and config are available.
    // Pass the cartId, if the caller specified one. (That will
    // happen during the initial redirect to PayHub.)
    await store.getters['PayHub/authPromise']
    await store.dispatch('Cart/loadCart', { create, merge, cartId })
  }
  finally {
    wait.end('loading cart')
  }
  // Switch carts when config.cart.client or config.cart.site changes
  bus.$on('config.configChanged', (config) => {
    const oldClient = store.getters['Cart/client']
    const newClient = config.cart.client
    const oldSite = store.getters['Cart/site']
    const newSite = config.cart.site
    if (oldClient !== newClient || oldSite !== newSite) {
      store.dispatch('Cart/loadCart', { create: true, merge: true })
    }
  })
}
