<template>
  <div class="position-relative ml-sm-5 w-100">
    <b-form-checkbox
      v-model="optIn"
      :state="true"
      class="align-items-start"
      inline
      data-test="rex-reminder-opt-in"
      @change="$emit('changePreference', optIn)"
    >
      <strong class="text-body">
        {{ $t("rex.reminder.opt_in") }}
      </strong>
    </b-form-checkbox>
  </div>
</template>

<script>

export default {
  emits: ['changePreference'],
  data () {
    return {
      optIn: true, // default opt-in
    }
  },
}
</script>
