import { sentryException } from '../sentry.js'
import { user } from '@grantstreet/login'

/* This module lets applications install HTTP API clients. In order
 * to let them handle refreshes of the JWT access token, PayHub will
 * pass the constructors, and the getters here will create API-client
 * objects on demand, using the current access token.
 */

const builder = (name) => (state, getters, rootState, rootGetters) => {
  const API = state[name]

  const getLocale = () => {
    return rootGetters['PayHub/locale']
  }

  if (API && typeof API === 'object') {
    API.getLocale = getLocale
    API.app = 'govhub-ui'
    API.exceptionLogger ||= sentryException
    return API
  }
  if (typeof API === 'function') {
    return new API({
      jwt: user.getAccessToken(),
      getLocale,
      app: 'govhub-ui',
      exceptionLogger: sentryException,
    })
  }
}

export default {
  namespaced: true,

  // State needs to be declared
  state: {
    cart: null,
    ewallet: null,
    contact: null,
    requestService: null,
    login: null,
    announcements: null,
    myPayments: null,
    myItems: null,
    schedPay: null,
    delivery: null,
  },

  getters: {
    cart: builder('cart'),
    ewallet: builder('ewallet'),
    contact: builder('contact'),
    requestService: builder('requestService'),
    login: builder('login'),
    announcements: builder('announcements'),
    myPayments: builder('myPayments'),
    myItems: builder('myItems'),
    schedPay: builder('schedPay'),
    delivery: builder('delivery'),
  },

  // Each of these takes either an API object or a
  // constructor. If you pass a constructor, it will
  // be invoked (and passed the current access token)
  // whenever you "get" it later on.

  mutations: {
    setCartApi (state, api) {
      state.cart = api
    },
    setEWalletApi (state, api) {
      state.ewallet = api
    },
    setContactApi (state, api) {
      state.contact = api
    },
    setRequestApi (state, api) {
      state.requestService = api
    },
    setLoginApi (state, api) {
      state.login = api
    },
    setAnnouncementsApi (state, api) {
      state.announcements = api
    },
    setMyPaymentsApi (state, api) {
      state.myPayments = api
    },
    setMyItemsApi (state, api) {
      state.myItems = api
    },
    setSchedPayApi (state, api) {
      state.schedPay = api
    },
    setDeliveryApi (state, api) {
      state.delivery = api
    },
  },
}
