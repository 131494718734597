import { createRouter, createWebHistory } from 'vue-router'
import store from './store/index.js'
import { isProd, needsBasicAuth } from '@grantstreet/psc-environment'
import cloneDeep from 'lodash/cloneDeep.js'
import { i18n, getMessageMap, bang } from '@grantstreet/psc-vue/utils/i18n.ts'
import { formatParams } from '@grantstreet/psc-js/utils/routing.js'
import VueCookies from 'vue-cookies'
import { sentryException } from './sentry.js'
import { configState, configGetters } from '@grantstreet/psc-config'

import LoadFailure from './views/LoadFailure.vue'
import ErrorPage from './views/ErrorPage.vue'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.js'

// These are the most common route entrypoints to GovHub. Future entrypoints
// to GovHub should also be added here.
// Ideally, it would be nice to dynamically import these as well. But, that
// increases the cumulative layout shfit the user experiences.
// That makes the site loading appear more disorienting.
import Home from './views/Home.vue'
import CheckoutLanding from './views/CheckoutLanding.vue'
import Search from './views/Search.vue'

const Forms =
  () => import(/* webpackChunkName: "govhub-ui-forms" */'./views/Forms.vue')
const ConfirmationPage =
  () => import(/* webpackChunkName: "govhub-ui-checkout" */'./views/Checkout/ConfirmationPage.vue')
const NonprodSignIn =
  () => import(/* webpackChunkName: "govhub-ui-nonprod-sign-in" */'./views/NonprodSignInPage.vue')
const Receipt =
  () => import(/* webpackChunkName: "govhub-ui-checkout" */'./views/Receipt.vue')
const ReceiptRedirect =
  () => import(/* webpackChunkName: "govhub-ui-checkout" */'./views/ReceiptRedirect.vue')
const MyDashboard =
  () => import(/* webpackChunkName: "govhub-ui-my-dashboard" */'./views/MyDashboard.vue')
const TaxSys =
  () => import(/* webpackChunkName: "govhub-ui-taxsys" */'./views/TaxSys.vue')
const Login =
  () => import(/* webpackChunkName: "govhub-ui-login" */'@grantstreet/login/src/components/Login.vue')
const UploadPage =
  () => import(/* webpackChunkName: "govhub-ui-bulk-add-to-cart" */'./views/BulkAddToCart/UploadPage.vue')
const ReviewPage =
  () => import(/* webpackChunkName: "govhub-ui-bulk-add-to-cart" */'./views/BulkAddToCart/ReviewPage.vue')
const PaymentOptionsPage =
  () => import(/* webpackChunkName: "govhub-ui-bulk-add-to-cart" */'./views/BulkAddToCart/PaymentOptionsPage.vue')
const SuccessPage =
  () => import(/* webpackChunkName: "govhub-ui-bulk-add-to-cart" */'./views/BulkAddToCart/SuccessPage.vue')
const EbillingPage =
  () => import(/* webpackChunkName: "govhub-ui-e-billing" */'./views/EbillingSubscription.vue')

// The vue-router instance'
let router

// Routes shared between all payhub repos:
const baseRoutes = [
  {
    // Only enabled on sunshine/demo. Path/route subject to change
    path: '/:client(sunshine)/:site(demo)?/taxsys/:taxsysSlugs(.*)*',
    name: 'taxsys',
    meta: {
      fullWidth: true,
      title: {
        en: 'TaxSys',
        es: 'TaxSys',
      },
      disableForEPS: true,
    },
    component: TaxSys,
    props: true,
  },
  {
    // ⚠️ CAUTION: This route is an Embedded Public Site entry path.
    // This path should not be changed without coordination of all EPS clients.
    path: '/:client/:site?/checkout',
    name: 'checkout',
    meta: {
      hideCartIcon: true,
      fullWidth: true,
      title: getMessageMap('checkout.title'),
    },
    component: CheckoutLanding,
    props: true,
  },
  {
    path: '/:client/:site?/confirmation',
    name: 'confirmation',
    component: ConfirmationPage,
    meta: {
      hideCartIcon: true,
      fullWidth: true,
      title: getMessageMap('checkout.confirmation.title.page'),
    },
  },
  {
    path: '/:client/:site?/receipt/:receiptId',
    name: 'receipt',
    component: Receipt,
    meta: {
      title: getMessageMap('payments.receipt'),
      hideCartIcon: true,
      redirectHomeOnLogout: true,
      fullWidth: true,
    },
  },
  {
    path: '/:client/:site?/receipt/:receiptId/redirect',
    name: 'receipt-redirect',
    component: ReceiptRedirect,
    meta: {
      title: getMessageMap('payments.receipt'),
      redirectHomeOnLogout: true,
    },
  },
  {
    path: '/:client/:site?/redirect/:cartId',
    name: 'redirect',
    meta: {
      hideCartIcon: true,
      title: getMessageMap('cart.default'),
    },

    // Only displayed if install.js cannot handle the redirect:
    component: ErrorPage,
    props: {
      type: 'cartError',
      isRedirect: true,
    },
  },

  // This is a blank page the user is redirected to during login. See
  // handleCallbackActions in @grantstreet/login/src/install.js for the actual login cod
  {
    path: '/(logout|callback)',
    name: 'callback',
    component: Login,
    props: {},
    meta: {
      disableForEPS: true,
    },
  },

  // Errors:
  {
    path: '/:client/:site?/load-failure',
    name: 'load-failure',
    component: LoadFailure,
    meta: {
      title: getMessageMap('error.default'),
    },
  },
  {
    path: '/:client/:site?/login-error',
    name: 'login-error',
    component: ErrorPage,
    props: {
      type: 'loginError',
    },
    meta: {
      skipModal: true,
      hideCartIcon: true,
      title: getMessageMap('error.default'),
    },
  },
  {
    path: '/:client/:site?/cart-error',
    name: 'cart-error',
    component: ErrorPage,
    props: {
      type: 'cartError',
    },
    meta: {
      skipModal: true,
      hideCartIcon: true,
      title: getMessageMap('error.default'),
    },
  },
  {
    path: '/:client/:site?/redirect-error',
    name: 'redirect-error',
    component: ErrorPage,
    props: {
      type: 'cartError',
      isRedirect: true,
    },
    meta: {
      skipModal: true,
      hideCartIcon: true,
      title: getMessageMap('error.default'),
    },
  },
  {
    // This route is used by cart-redirect BE only. PSC-3858 will remove it
    path: '/:client/:site?/limit-exceeded',
    name: 'limitExceeded',
    component: ErrorPage,
    props: {
      type: 'limitExceeded',
      hideSupportLink: true,
    },
    meta: {
      skipModal: true,
      hideCartIcon: true,
      title: bang(getMessageMap('error.limitExceeded.title')),
    },
  },
  {
    path: '/:client/:site?/network-error',
    name: 'networkError',
    component: ErrorPage,
    props: {
      type: 'networkError',
      hideSupportLink: true,
    },
    meta: {
      skipModal: true,
      hideCartIcon: true,
      title: bang(getMessageMap('error.networkError.title')),
    },
  },
  {
    path: '/:client?/:site?/nonprod-sign-in',
    name: 'nonprodSignIn',
    component: NonprodSignIn,
    meta: {
      disableForEPS: true,
    },
  },
  {
    path: '/:client/:site?',
    name: 'home',
    component: Home,
    alias: '/:client',
    meta: {
      fullWidth: true,
      fullWidthFooter: true,
      disableForEPS: true,
    },
    beforeEnter ({ params }, from, next) {
      const { siteUsesHomepage, siteUsesRedirectOnly, siteUsesFormsOnly } = configGetters
      // For search sites continue as usual
      if (siteUsesHomepage) {
        next()
        return
      }

      console.warn('Home page is disabled for non-search sites')
      // For cart redirect sites return to checkout
      if (siteUsesRedirectOnly) {
        next({
          name: 'checkout',
          params,
        })
        return
      }

      // For forms only sites go to forms home
      if (siteUsesFormsOnly) {
        // Until PSC-9031, this will 404.
        next({
          name: 'forms',
          params,
        })
        return
      }

      // Otherwise abort navigation
      // TODO: It's probably worth considering adding a setting like
      // config.payhub.homeRoute or landingRoute that could supersede these
      // cases and add the flexibility we'll eventually need. Instead of
      // duck-typing out the home route.
      // This used to be next(false) back when we were using MFEs. That doesn't
      // seem to work the same in this new non MFE world and breaks the
      // marketing redirect so switching to next('/') which is hopefully
      // equivalent. If it's not, more investigation may be necessary.
      next('/')
    },
  },
  {
    path: '/:client/:site?/ebilling/:subscriptionId/:action/:authCode?',
    name: 'e-billing-subscription',
    component: EbillingPage,
    meta: {
      hideFooter: true,
      hideCartIcon: true,
    },
  },
  {
    path: '/:client/:site?/:pathMatch(.*)*',
    name: 'error',
    component: ErrorPage,
    props: {
      type: 'notFound',
    },
    meta: {
      hideCartIcon: true,
      title: getMessageMap('error.notFound.title'),
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'marketing-redirect',
    beforeEnter () {
      sentryException(`Bouncing user from ${window.location} to GSG homepage`, {
        level: 'warning',
      })
      window.location.replace('https://www.grantstreet.com')
    },
  },
]

// *NOTE:* See the GHA router for documentation on how to use meta properties.

const configRoutes = [
  ['useMyDashboard', {
    // ⚠️ CAUTION: This route is an Embedded Public Site entry path.
    // This path should not be changed without coordination of all EPS clients.
    path: '/:client/:site?/my-dashboard',
    name: 'my-dashboard',
    component: MyDashboard,
    meta: {
      title: getMessageMap('topnav.dashboard'),
    },
  }],

  // Handle legacy urls. These have all been rolled into my dashboard, but we
  // still have links to my-payments in emails and such that we don't want to
  // break, so redirect to the new thing. Including the other two urls just in
  // case and for consistency. Also include a hash so we can open the correct
  // tab.
  ['useMySavedItems', {
    path: '/:client/:site?/my-saved-items',
    name: 'my-saved-items',
    redirect: { name: 'my-dashboard', hash: '#my-items' },
  }],
  ['useMyPayments', {
    path: '/:client/:site?/my-payments',
    name: 'my-payments',
    redirect: { name: 'my-dashboard', hash: '#my-payments' },
  }],
  ['useLogin', {
    path: '/:client/:site?/my-profile',
    name: 'my-profile',
    redirect: { name: 'my-dashboard', hash: '#my-settings' },
  }],
  ['useForms', {
    path: '/:client/:site?/forms/:slug?',
    name: 'forms',
    meta: {
      title: getMessageMap('govhub.forms'),
      hideFooter: false,
      narrowPage: true,
      // TODO PSC-9031 linkInNav once more
      linkInNav: false,
      navOrder: 2000,
      navIcon: 'clipboard',
    },
    component: Forms,
    props: true,
  }],

  // These routes and settings are intended to be temporary. CX will eventually
  // develop a generalized mechanism to enable settings as cards on the homepage
  // and nav links in the menu.
  // The getters for these TaxSys settings check that a flag.
  ['useBtExpress', {
    path: '/:client/:site?/btexpress/:taxsysSlugs(.*)*',
    name: 'btexpress',
    component: TaxSys,
    meta: {
      taxsysPath: 'btexpress',
      title: {
        // TaxSys isn't localizing yet
        en: 'BTExpress™️',
        es: 'BTExpress™️',
      },
    },
  }],
  ['useReports', {
    path: '/:client/:site?/reports/:taxsysSlugs(.*)*',
    name: 'taxsys-reports',
    component: TaxSys,
    meta: {
      taxsysPath: 'reports',
      title: {
        en: 'Reports',
        es: 'Reportes',
      },
      navIcon: 'line-graph',
      linkInNav: true,
    },
  },
  'reportsLinkMeta'],

]

// Creates the router instance. This first loads the configs for the passed
// client and site and then it loads the search page routes for that site. If
// baseRoutes are passed, they are added to the base set of routes that are
// shared between all sites.
//
// We create a brand new router instance every time we navigate to a new site
// (or client) because the new site will likely have different routes and
// vue-router does not let you remove routes from an existing router instance.
// You can only add routes. See:
// https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
//
export async function initRouter () {
  // TODO: Think about what needs to happen when config changes
  const config = configState.config
  const getters = configGetters

  const payableSourcesRoutes = configPayableSourcesRoutes(config) || []
  const redirectLinks = configRedirectLinks(config) || []

  // TODO: Do we really need to cloneDeep twice here?
  const routes = redirectLinks.concat(cloneDeep(payableSourcesRoutes.concat(cloneDeep(baseRoutes))))

  // Add the config-based routes to the top (we want My Items to appear
  // above all the other sidebar pages)
  for (let [key, route, metaKey] of configRoutes) {
    if (getters[key]) {
      if (getters[metaKey]) {
        route = {
          ...route,
          meta: {
            ...route.meta,
            ...getters[metaKey],
          },
        }
      }
      routes.unshift(route)
    }
  }

  store.commit('PayHub/setRoutes', routes)

  const newRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior (_to, _from, savedPosition) {
      // Scroll to top when navigating to new routes
      return savedPosition || { left: 0, top: 0 }
    },
    routes,
  })
  if (!router) {
    router = newRouter
  }
  else {
    // Replace only the router, otherwise there will be "route with name xxx
    // does not exist" warnings.
    router.matcher = newRouter.matcher
  }

  newRouter.beforeEach(async (to, from, next) => {
    // Don't render stub sites
    const isStubSite = configState.siteMeta[config.client]?.[config.site]?.type === 'stub'
    if (isStubSite && to.path !== '/') {
      return next('/')
    }

    // Some routes are disabled when govhub runs as an embedded public site.
    // Navigation to certain routes such as the home page must be intercepted.
    // When the user navigates to disabled routes, they will be redirected to
    // the cart page, and an error will be logged to sentry.
    if (configGetters.useEmbeddedPublicSite && to.meta?.disableForEPS) {
      next({
        params: { ...to.params, client: from.params.client, site: from.params.site },
        name: 'checkout',
      })
      sentryException(`EPS user tried to navigate to invalid route (${to.fullPath}). They were redirected to the checkout page.`, {
        level: 'warning',
      })
      return
    }

    // Formatting is critical when reading params like client and site
    const formattedParams = formatParams(to.params)

    // The useDemoSiteAuth site setting is disabled for sunshine in nonprod as
    // this would cause our test suite to fail. However, sunshine should not be
    // accessible in prod. Leaving this setting enabled in prod but disabled in
    // nonprod would be prone to us accidentally breaking that intentional
    // environment difference. We could fix the test suite, but hardcoding the
    // authentication challenge for sunshine in prod is a much smaller task.
    const isProdSunshine = formattedParams.client === 'sunshine' && isProd()

    // Intercept to show "Basic Auth" substitute
    const needsDemoSiteAuth = (config && config.payHub && config.payHub.useDemoSiteAuth) || isProdSunshine
    const requiresAuth = (needsBasicAuth() || needsDemoSiteAuth) && !configGetters.useEmbeddedPublicSite

    let signedIn
    if (requiresAuth) {
      let signedInKey
      if (needsDemoSiteAuth) {
        const { client, site } = formattedParams
        // XXX PSC-11644
        // If we introduce a test site with a default URL this will break
        let siteKey = site
        if (!site) {
          siteKey = client
        }
        signedInKey = `${client}.${siteKey}`
      }
      else {
        signedInKey = 'Nonprod'
      }

      const signedInMap = store.getters['PayHub/signedInToNonprod']
      signedIn = signedInMap[signedInKey] || VueCookies.get(signedInKey)
    }

    if (to.name !== 'nonprodSignIn' && requiresAuth && !signedIn && !isStubSite) {
      const redirectCartMatch = window.location.pathname.match('^/[^/]+(?:/[^/]+)?/redirect/([^/]+)')
      const additionalParams = { ...to.params }
      return next({
        name: 'nonprodSignIn',
        params: to.params,
        state: {
          query: to.query,
          additionalParams,
          redirectCartId: redirectCartMatch ? redirectCartMatch[0] : null,
        },
        query: {
          returnTo: to.name,
          returnAt: to.hash,
        },
      })
    }

    // Pay close attention here to whether you should be using the formatted or
    // raw versions of these
    const formattedClient = formattedParams.client
    const formattedSite = formattedParams.site
    if (formattedClient) {
      if (!config || Object.keys(config).length === 0) {
        console.warn(`Unknown site '${formattedSite}' for client '${formattedClient}'`)
        return next('/')
      }

      // Handle Default Site Clients navigating to an unknown route.
      // There is a site in the URL but it doesn't match any site or module.
      // Redirect to the default site home.
      if (config.defaultSiteUrl && formattedSite && formattedSite !== config.site) {
        return next({ name: 'home', params: { client: to.params.client } })
      }

      // Handle client-only URLs (e.g., /sacramento)
      if (config.useClientOnlyUrl && formattedSite) {
        // This is supposed to be a client-only url...

        if (formattedSite === config.site) {
          // Redirect e.g. /sacramento/sacramento to just /sacramento

          // This is a better way to remove props than the delete operator.
          // See psc-js/utils/objects.js for more.
          const { params, path, fullpath, ...newTo } = to
          const { site, ...newParams } = params
          newTo.params = newParams

          return next(newTo)
        }
        else if (to.name !== 'error') {
          // The user navigated to an unknown route, so send them home (there is
          // no good way to redirect to our 404 page in this case without an
          // infinite loop)
          return next({ name: 'home', params: { client: to.params.client } })
        }
      }

      // Check if there is a LaunchDarkly flag for disabling this site in prod.
      // If so, and if that flag is explicitly set to false, disable the site in
      // prod. Or if we have a enableInProd config that is
      // set to false, disable the site. The LD flag will override the config.
      const flags = configState.flags || {}
      const flag = flags[`enable-site-in-prod.${formattedClient}-${formattedSite}`]
      if (
        isProd() && (
          flag !== true && (config.enableInProd === false || flag === false)
        )
      ) {
        console.warn(`Site '${formattedSite}' for client '${formattedClient}' is not enabled`)
        return next('/')
      }
    }

    // If this is a named route like { name: "home" }, the client-only version
    // of the URL will be the one matched first, so `to` will not have a site.
    // This means that the user will be incorrectly routed to /sunshine instead
    // of /sunshine/demo. This block restores the `site` param from `from`.
    if (from.params.client === to.params.client && from.params.site && !to.params.site) {
      return next({
        ...to,
        params: { ...to.params, site: from.params.site },
      })
    }

    if (
      // Navigating within GovHub
      from?.path !== '/' &&
      // Changed clients or sites
      (formattedClient !== config.client || (
        formattedSite !== config.site &&
        // (but "changing" to undefined is okay when there's some type of default)
        !(
          (formattedSite === undefined || formattedSite === '') &&
          (config.useClientOnlyUrl || config.defaultSiteUrl)
        )
      ))
    ) {
      // This originally would load new configs for a new client/site
      // This shouldn't happen because users can't navigate between sites
      // without reloading the page. See more discussion in PSC-10885.
      const message = 'Navigated to new client/site without refreshing'
      throw new Error(message)
    }

    next()
  })

  newRouter.beforeResolve((to, from, next) => {
    // If configs were going to change they have by this point
    const mostSpecific = to.matched.slice().reverse().find(
      ({ meta }) => meta && meta.title,
    )
    const title = mostSpecific?.meta?.title?.[i18n?.locale]
    setTitle(title)

    store.dispatch('PayHub/logRequest', {
      url: to.path,
      referer: from.matched?.length > 0 ? `${window?.location?.origin}${from.fullPath}` : document?.referrer,
    })

    next()
  })

  // Emit this route changed event after every navigation.
  // The govhub installer will listen to this event when running as an embedded
  // public site.
  newRouter.afterEach((to, from) => {
    EventBus.$emit('routeChange', to)
  })

  return router
}

// TODO: PSC-9145 consider removing this because title is set by
// Vue-Meta in the App component (or further down the component tree).
function setTitle (pageTitle = '', overrideTitle = false) {
  const clientTitle = store.getters['PayHub/clientTitle']
  let title = pageTitle

  if (!overrideTitle && clientTitle !== 'clientTitle') {
    if (title.length) {
      title += ' - '
    }
    title += clientTitle
  }

  if (title.length === 0) {
    return
  }
  document.title = title
}

const isTaxsysAdapter = payablesAdaptor => /Taxsys-GovHub\/v/i.test(payablesAdaptor)

const allowDisplayTypeForTaxsysSite = displayType =>
  // Part of a new, medium-term workaround to enable a whole slew of features.
  (
    // In this case it enables these display types which are otherwise disabled
    // on taxsys sites
    displayType !== 'property-tax' &&
    displayType !== 'business-tax'
  ) ||
  configGetters.useTaxsysToGovhubFeatures

function configPayableSourcesRoutes ({ client, site, useClientOnlyUrl, defaultSiteUrl }) {
  const searchPages = store.getters['PayHub/searchPages']
  if (!searchPages) {
    return
  }

  const flags = configState.flags

  const payableSourcesRoutes = []
  for (const {
    route,
    searchType,
    payablesAdaptor,
    icon,
    searchInputs,
    exampleImage,
    exampleImageText,
    exampleImageAltText,
    pageImageTop,
    pageImageLeft,
    blindDisplayNameLabel,
    blindDisplayNamePlaceholder,
    expandComponentKey,
    itemName,
    generateUniqueId,
    title,
    subtitle,
    description,
    navOrder,
    displayType,
    hideInProd,
    searchApi,
    enableBulkAddToCart,
    bulkAddToCartFileFormat,
    bulkAddToCartMaxItems,
    bulkAddToCartContactTitle,
    bulkAddToCartContactEmail,
    displayBulkAddContact,
    bulkAddToCartInformationalPacket,
  } of searchPages) {
    if (!route?.length) {
      sentryException(new TypeError(`Invalid payable source route "${route}"`))
      continue
    }
    // Don't allow navigation if taxsys source isn't enabled
    if (isTaxsysAdapter(payablesAdaptor) && !allowDisplayTypeForTaxsysSite(displayType)) {
      continue
    }

    // Add search page
    const path = (useClientOnlyUrl ? '/:client/' : `/:client/:site${defaultSiteUrl && '?'}/`) + route

    // If force-show-payable-source is enabled we should always show.
    // Only don't show if we're in prod and hideInProd is true
    if (flags[`force-show-payable-source.${client}.${site}.${displayType}`] ||
      !(isProd() && hideInProd)) {
      const payableSourcesRoute = {
        path,
        name: route,
        component: Search,
        props: {
          searchType,
          payablesAdaptor,
          icon,
          searchInputs,
          exampleImage,
          exampleImageText,
          exampleImageAltText,
          displayType,
          blindDisplayNameLabel,
          blindDisplayNamePlaceholder,
          expandComponentKey,
          itemName,
          generateUniqueId,
          enableBulkAddToCart,
          bulkAddToCartFileFormat,
          bulkAddToCartMaxItems,
          bulkAddToCartContactTitle,
          bulkAddToCartContactEmail,
          displayBulkAddContact,
          bulkAddToCartInformationalPacket,
        },
        meta: {
          title,
          subtitle,
          description,
          displayType,
          navOrder,
          navIcon: icon,
          // Sometimes we add new payable sources but want to hide sidebar
          // access until a planned release.
          // - force-show-payable-source allows us to do session targeted prod
          //   testing. If true we should always show.
          // - hide-sidebar-payable-source and hideInProd essentially do the
          //   same thing but the site setting is a more robust and permanent
          //   solution. If either of those is on and we don't have force-show
          //   on then we should hide.
          linkInNav: flags[`force-show-payable-source.${client}.${site}.${displayType}`] ||
          !(
            flags[`hide-sidebar-payable-source.${client}.${site}.${displayType}`] ||
            (isProd() && hideInProd)
          ),
          isSearchPage: true,
          pageImageTop,
          pageImageLeft,
          route,
        },
      }

      payableSourcesRoutes.push(payableSourcesRoute)

      const additionalRoutes = configAdditionalPayableSourcesRoutes(payableSourcesRoute)

      if (additionalRoutes) {
        additionalRoutes.forEach(route => payableSourcesRoutes.push(route))
      }
    }

    // If the search page is a TaxSys index search, then add an iframe page too
    if (searchType === 'index-search' && isTaxsysAdapter(payablesAdaptor)) {
      // One for the encoded payable results
      payableSourcesRoutes.push({
        path: `${path}/:encodedPayable/:taxsysSlugs(.*)*`,
        name: route + '-details',
        component: TaxSys,
        props: {
          payablesAdaptor,
          displayType,
        },
        meta: {
          displayType,
        },
        // 404 if the required taxsys setting isn't set
        beforeEnter (to, from, next) {
          if (!configState.config.taxsys?.taxsysClientId) {
            console.warn('No taxsysClientId set.')
            next({
              path: `${client}/${site}/404`,
            })
            return
          }

          next()
        },
      })

      // And, if confidential search is enabled, then add the conf page
      if (
        searchApi?.confidentialSearch?.field?.label &&
        searchApi?.confidentialSearch?.field?.id &&
        searchApi?.confidentialSearch?.field?.name
      ) {
        payableSourcesRoutes.push({
          path: `${path}/bills/:confidentialPayableId/:taxsysSlugs(.*)*`,
          name: route + '-confidential',
          component: TaxSys,
          props: {
            payablesAdaptor,
            displayType,
          },
          meta: {
            displayType,
          },
          // 404 if the required taxsys setting isn't set
          beforeEnter (to, from, next) {
            if (!configState.config.taxsys?.taxsysClientId) {
              console.warn('No taxsysClientId set.')
              next({
                path: `${client}/${site}/404`,
              })
              return
            }

            next()
          },
        })
      }
    }
  }

  return payableSourcesRoutes
}

function configAdditionalPayableSourcesRoutes (payableSourceRoute) {
  const { props } = payableSourceRoute
  const { enableBulkAddToCart } = props

  let additionalRoutes = []

  if (enableBulkAddToCart) {
    const bulkAddToCartRoutes = createBulkAddToCartRoutes(payableSourceRoute)
    additionalRoutes = additionalRoutes.concat(bulkAddToCartRoutes)
  }

  return additionalRoutes
}

function createBulkAddToCartRoutes (payableSourceRoute) {
  const bulkAddToCartParams = [
    {
      path: '/upload',
      name: '-upload',
      component: UploadPage,
    },
    {
      path: '/upload/review',
      name: '-upload-review',
      component: ReviewPage,
    },
    {
      path: '/upload/confirmation',
      name: '-upload-confirmation',
      component: PaymentOptionsPage,
    },
    {
      path: '/upload/success',
      name: '-upload-success',
      component: SuccessPage,
    },
  ]

  return bulkAddToCartParams.map(({ path, name, component }) => {
    const bulkAddToCartRoute = cloneDeep(payableSourceRoute)
    bulkAddToCartRoute.path += path
    bulkAddToCartRoute.name += name
    bulkAddToCartRoute.component = component
    bulkAddToCartRoute.meta.linkInNav = false

    return bulkAddToCartRoute
  })
}

function configRedirectLinks (config) {
  const redirectLinks = store.getters['PayHub/redirectLinks']
  if (!redirectLinks) return

  const flags = configState.flags

  return redirectLinks
    // TODO: Change back to map once PSC-13137 LD flag is removed
    .reduce((list, {
      route,
      icon,
      searchType,
      title,
      navOrder,
      displayType,
      displayInNavbar,
      hideInProd,
      // TODO: Remove once the PSC-13137 LD flag is removed
      payablesAdaptor,
    }) => {
      // Don't allow navigation if taxsys source isn't enabled
      if (isTaxsysAdapter(payablesAdaptor) && !allowDisplayTypeForTaxsysSite(displayType)) {
        return list
      }
      list.push({
        path: `/${route}`,
        props: {
          icon,
          searchType,
        },
        meta: {
          title,
          displayType,
          navOrder,
          link: route,
          navIcon: icon,
          type: searchType,
          // Sometimes we add new payable sources but want to hide sidebar
          // access until a planned release.
          // - force-show-payable-source allows us to do session targeted prod
          //   testing. If true we should always show.
          // - hide-sidebar-payable-source and hideInProd essentially do the
          //   same thing but the site setting is a more robust and permanent
          //   solution. If either of those is on and we don't have force-show
          //   on then we should hide.
          linkInNav: displayInNavbar &&
          (
            flags[`force-show-payable-source.${config.client}.${config.site}.${displayType}`] ||
            !(
              flags[`hide-sidebar-payable-source.${config.client}.${config.site}.${displayType}`] ||
              (isProd() && hideInProd)
            )
          ),
        },
      })
      return list
    }, [])
}

export default router
