import schedPayStore from './store/index.js'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'
import { loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'
import { user } from '@grantstreet/login'
import { sentryException } from './sentry.js'

export default function install (Vue, {
  store,
  bus,
  api,
  isAdmin,
}) {
  Vue.use(VueDOMPurifyHTML, sanitizeConfig)
  store.commit('API/setSchedPayApi', api)
  if (!store.state.SchedPay) {
    store.registerModule('SchedPay', schedPayStore)
  }

  // Create an async iffe
  // More efficient than a promise with callback nesting
  const promise = isAdmin ? Promise.resolve() : (async () => {
    await store.getters['PayHub/authPromise']
    if (!store.getters['Cart/client'] || !store.getters['Cart/site']) {
      console.warn("SchedPay can't load cart without client/site - aborting")
    }
    if (user.loggedIn) {
      await store.dispatch('SchedPay/retrieveUserSchedules')
    }
  })()

  store.commit('SchedPay/setLoadPromise', promise)

  bus.$on('login.callbackAction', async ({ user, action }) => {
    if (user && action.type === 'openSchedPay') {
      store.commit('SchedPay/setAutoloadPath', action.payable)
    }
  })

  // Add SchedPay translations
  loadTranslations(sentryException)
}
