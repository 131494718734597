import Vue from 'vue'
import Vuex from 'vuex'
import { i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.js'
import { searchPayablesPaths } from '@grantstreet/payables'
import { configState } from '@grantstreet/psc-config'
import { user } from '@grantstreet/login'

Vue.use(Vuex)

const state = {
  // Maps payable URLs to payable objects
  savedItems: {},
  // Load promise indicates if the user's saved items were successfully loaded
  // from payables. This will have resolved if successful, and will have been
  // rejected if unsuccessful.
  loadPromise: null,
  // Since we are now catching the exception thrown by the rejected loadPromise,
  // if we fail to load a user's payables, await loadPromise will return
  // 'undefined'. To fix this in the short term, we will update this value when
  // we catch the promise rejection. This will be used to display a banner to
  // the user on the MSI dashboard page. We should rethink if this is a good
  // long-term solution after the issue has been resolved.
  failedToLoadUserPayables: false,
}

const getters = {
  savedItems: state => state.savedItems,

  // Initialization promise
  loadPromise: state => {
    if (state.loadPromise) {
      return state.loadPromise
    }
    throw new Error('MyItems/loadPromise is not initialized')
  },

  isMyThing: state => payablePath => {
    return Boolean(state.savedItems[payablePath])
  },

  myItemsWarningLimit: state => displayType => {
    return Object.values(state.savedItems).filter(payable => payable.displayType === displayType).length > 4
  },
}

const mutations = {
  clearMyItemsLocal (state) {
    Vue.set(state, 'savedItems', {})
  },
  addToMyItemsLocal (state, payable) {
    Vue.set(state.savedItems, payable.savePath, payable)
  },
  removeFromMyItemsLocal (state, payable) {
    Vue.delete(state.savedItems, payable.savePath)
  },
  setLoadPromise (state, loadPromise) {
    Vue.set(state, 'loadPromise', loadPromise)
  },
  setFailedToLoadUserPayables (state) {
    state.failedToLoadUserPayables = true
  },
}

const actions = {
  async loadMySavedItems ({ rootState, rootGetters, commit }) {
    const api = rootGetters['API/myItems']

    // This is not anonymous-friendly
    if (!user.loggedIn) {
      return
    }

    commit('clearMyItemsLocal')

    let { data: savedItems = [] } = await api.getSavedItems(configState.config.client, configState.config.site)

    if (!savedItems || !savedItems.length) {
      return
    }

    // Ensure all paths are prepended by a '/'
    savedItems = savedItems.map(path => (path && path[0] !== '/') ? `/${path}` : path)

    // Filter out any saved items that do not belong to the available payable
    // adaptors. This protects against a UI error being displayed for an item
    // that was saved prior to an adaptor being deleted.
    const adaptors = rootGetters['PayHub/searchPages']?.map(searchPage => searchPage?.payablesAdaptor)
    savedItems = savedItems.filter(path => adaptors?.find(adaptor => path.includes(adaptor)))

    const params = {
      paths: savedItems,
      language: i18n.global.locale.value,
    }

    // This appears to be the right place to load the Payables
    ;(await searchPayablesPaths(params)).forEach(payable => {
      if (payable) {
        commit('addToMyItemsLocal', payable)
      }
    })
  },

  toggleMyItem ({ getters, dispatch }, { payable }) {
    if (getters.isMyThing(payable.savePath)) {
      return dispatch('removeFromMyItems', { payable })
    }
    else {
      return dispatch('addToMyItems', { payable })
    }
  },

  async addToMyItems ({ rootGetters, rootState, commit }, { payable }) {
    await rootGetters['API/myItems'].saveItem(configState.config.client, configState.config.site, payable.savePath)
    commit('addToMyItemsLocal', payable)
    EventBus.$emit('msi.added', { payable })
  },

  async removeFromMyItems ({ rootGetters, rootState, commit }, { payable }) {
    await rootGetters['API/myItems'].unsaveItem(configState.config.client, configState.config.site, payable.savePath)
    commit('removeFromMyItemsLocal', payable)
    EventBus.$emit('msi.removed', { payable })
  },
}

const store = {
  state,
  getters,
  mutations,
  actions,

  namespaced: true,
}

export default store
