import cloneDeep from 'lodash/cloneDeep.js'
import isEqual from 'lodash/isEqual.js'
import { sortByProp } from '@grantstreet/psc-js/utils/sort.js'
import Client from './models/Client.ts'
import Site from './models/Site.ts'
import { sentryException } from './sentry.ts'
import { Module, ModuleMap, Setting } from './types/settings.ts'
import { isAxiosError } from 'axios'

/**
 * Converts site settings as returned from the config service to a flattened
 * version.
 *
 *  For example, it converts a raw object from this format:
 *
 *  {
 *    cart: {
 *      settings: {
 *        timeZone: {
 *          source: ...
 *          value: 'America/New_York',
 *        },
 *      },
 *    },
 *    search: {
 *      name: ...
 *      settings: {
 *        searchPages: {
 *          source: ...
 *          type: 'List',
 *          value: [{
 *            title: ...
 *            route: ...
 *          }],
 *        },
 *      },
 *    },
 *  }
 *
 * To this format:
 *
 *  {
 *    cart: {
 *      timeZone: 'America/New_York',
 *    },
 *    search: {
 *      searchPages: [{
 *        title: ...
 *        route: ...
 *      }],
 *    },
 *  }
 *
 * @param {Object} options object with the following keys and values
 * @param {Object} options.siteConfigs list of one site's settings from
 *  config service
 * @param {string} options.client current client
 * @param {string} options.site current site
 * @param {boolean} options.useClientOnlyUrl passed through to returned object
 * @param {string} options.defaultSiteUrl passed through to returned object
 *
 * @returns object in the format mentioned above. False if any of the first
 *  three parameters are empty
 */
export const formatSiteConfigFromService = ({
  siteConfigs,
  client,
  site,
  useClientOnlyUrl,
  defaultSiteUrl,
}: {
  siteConfigs: ModuleMap
  client: string
  site?: string
  useClientOnlyUrl: boolean
  defaultSiteUrl?: string
}) => {
  if (!siteConfigs || !client || !site) {
    return false
  }

  const flat: {[key: string]: string | boolean | Module} = {}
  for (const [moduleName, module] of Object.entries(siteConfigs)) {
    flat[moduleName] = {}
    for (const [settingName, setting] of Object.entries(module.settings || {})) {
      if (settingName === 'meta') {
        flat[moduleName][settingName] = setting
      }
      else {
        flat[moduleName][settingName] = flattenSetting(setting, moduleName, settingName, siteConfigs)
      }
    }
  }

  flat.client = client
  flat.site = site

  // Set this so the router can access it
  flat.useClientOnlyUrl = useClientOnlyUrl
  flat.defaultSiteUrl = defaultSiteUrl || ''

  return flat
}

/**
 * Recursively flattens settings retrieved from the Config service.
 *  @see formatSiteConfigFromService for example datastructure of configVal and
 *  the returned val
 *
 * @param {object} configVal Object containing setting or nested settings, array of
 *  settings or scalar value
 * @param {string} moduleName name of module being flattened. This is only used
 *  for the warning when a setting has a source without a value.
 * @param {string} settingName name of setting being flattened. This is only
 *  used for the warning when a setting has a source without a value.
 * @param {object} siteConfigs Object containing full site configs. This is passed
 *  through to @see findDependedConfig
 *
 * @returns
 *  - configVal if configVal is scalar (this can be null or undefined)
 *  - Array of values if configVal is an array or an array type config object
 *  - null if the config has a source property without a value
 *  - otherwise returns object mapping each nested config name to it's config
 *    value
 */
export const flattenSetting = (configVal, moduleName, settingName, siteConfigs) => {
  // configVal can be null when called recursively
  if (typeof configVal !== 'object' || !configVal) {
    return configVal
  }

  if (Array.isArray(configVal)) {
    return configVal.map(element =>
      flattenSetting(element.value || element, moduleName, settingName, siteConfigs),
    )
  }

  if (configVal.hasOwnProperty('source') && !configVal.hasOwnProperty('value')) {
    console.warn(`Config ${moduleName}/${settingName} has a source but no value. Using null.`)
    return null
  }

  // NOTE this currently fills in the hardcoded dependsOn property with
  // "payableSources.payableSources" as it is stripped away when publishing.
  // The only dependent setting currently is a dependent dropdown.
  // This will need to be updated whenever we add a new dependent setting type
  if (configVal.hasOwnProperty('value') && configVal.type === 'DependingDropdown') {
    // If this config depends on another one's value, we replace this config we're
    // currently looking at with the specified value of the one it depends on
    const newConfigVal = findDependedConfig(siteConfigs, 'payableSources.payableSources', configVal.value)
    return flattenSetting(newConfigVal, moduleName, settingName, siteConfigs)
  }

  if (configVal.hasOwnProperty('value')) {
    return flattenSetting(configVal.value, moduleName, settingName, siteConfigs)
  }

  type flattenedSetting = {
    en?: string
    es?: string
  }
  const flattened: flattenedSetting = {}
  for (const [key, value] of Object.entries(configVal)) {
    flattened[key] = flattenSetting(value, moduleName, settingName, siteConfigs)
  }

  // If this config value has an 'en' key, it is a "TranslatableText" setting
  // type. A missing Spanish translation can make the UI
  // unusable, so let's default missing translations to the English counterpart
  if (flattened.en && !flattened.es) {
    flattened.es = flattened.en
  }
  return flattened
}

/**
 * Looks up the providing value for a config that depends on it.
 *
 * @param {object} siteConfigs site settings as returned by the config service.
 *  @see formatSiteConfigFromService raw input as example
 * @param {string} dependency string in format of module.setting this is the
 *  setting being looked up. Currently, this looked up setting MUST be an array
 *  type setting.
 * @param {*} id iditifier to find the correct setting within the dependency
 *  array. This can either be an id string compared to each setting 'id' key, or
 *  can be the value of the desired setting.
 * @returns
 * - setting object contained in the specified module and array setting
 *   that has the specified id or value.
 * - null if the dependency setting does not exist.
 * - undefined if the setting exists but the id lookup doesn't match any entry.
 */
export const findDependedConfig = (siteConfigs, dependency, id) => {
  // Dependencies are written in the form of "module.setting"
  const [module, setting] = dependency.split('.')
  if (!siteConfigs[module]?.settings?.[setting]?.value ||
      !Array.isArray(siteConfigs[module].settings[setting].value)
  ) {
    return null
  }
  // NOTE this currently assumes the value we're looking through is an array
  // This is because our only dependent setting currently is a dependent dropdown
  // This will need to be updated whenever we add a new dependent setting type
  return siteConfigs[module].settings[setting].value.find(entry =>
    // entry can either be an object or a value
    entry === id || entry.id === id,
  )
}

// @ts-expect-error the flags/client/site params aren't used right now, but we
// want to keep them here as placeholders to make adding flagged overrides
// easier in the future. override in the future.
export const overrideFlaggedConfigs = ({ flags, config, config: { client, site } }) => {
  // NB: These configs require the config setting to be on *AND* the LD flag to
  // be set. The system we are going for is:
  //
  // 1. Add a new config/flag to this list
  // 2. Create the LD feature flag
  // 3. Use LD to target the feature
  // 4. Once everybody is targeted at 100%, remove the flag from this list
  // 5. Delete the feature flag

  // Template:
  // const myPaymentsFlag = flags['use-my-payments']
  // if (typeof myPaymentsFlag === 'boolean' && config.myPayments) {
  //   if (!config.myPayments.meta) {
  //     config.myPayments.meta = {}
  //   }
  //   config.myPayments.meta.enabled = config.myPayments.meta.enabled && myPaymentsFlag
  // }

  return config
}

const objectifyConfigKey = (result, key, value) => {
  // TODO: Use a more efficient process than successive splits/joins
  const slugs = key.split('/')
  const slug = slugs.shift()
  if (slugs.length === 0) {
    result[slug] = value
    return
  }
  if (!result[slug]) {
    result[slug] = {}
  }

  const newKey = slugs.join('/')
  objectifyConfigKey(result[slug], newKey, value)
}

// Turns the list of keys and values returned from the config service into a
// proper JS object (without the app name and 'data'/'data-pub' levels).
//
// For example, given this input:
//
//   {
//     /payhub/data-pub/clients/foo/id: "12345",
//     /payhub/data-pub/clients/foo/name: "Foo",
//   }
//
// This function would return this:
//
//   {
//     clients: {
//       foo: {
//         id: "12345",
//         name: "Foo",
//       }
//     }
//   }
// NOTE!
// Until PSC-17126 is complete, Direct Charge Widget relies directly on this!!
// Be extremely careful making changes.
export const objectifyConfigs: (data) => Setting = (data) => {
  const result = {}
  if (typeof data !== 'object') {
    return result
  }

  for (let [key, value] of Object.entries(data)) {
    // Strip off the first two URL levels (the app name and 'data'/'data-pub')
    key = key.replace(/^(\/[^/]*){2}\//, '')

    objectifyConfigKey(result, key, value)
  }
  return result
}

export const parseClientSettings = (moduleList, clientId, moduleSettings) => {
  if (!moduleSettings?.settings?.[clientId]) {
    return {}
  }
  moduleSettings = moduleSettings.settings[clientId]

  const modules = {}

  for (const module of Object.keys(moduleList)) {
    if (!modules[module]) {
      const blank = cloneDeep(moduleList[module])
      blank.settings = {}
      modules[module] = blank
    }

    if (!moduleSettings[module]) {
      continue
    }

    for (const [settingName, setting] of Object.entries(moduleSettings[module])) {
      modules[module].settings[settingName] = setting
    }
  }

  return modules
}

// Parses the Config service's settings response into an object
export const parseSiteSettings: (
  moduleList: ModuleMap,
  clientId: string,
  siteId: string,
  moduleSettings: Module
)=> ModuleMap = (
  moduleList: ModuleMap,
  clientId: string,
  siteId: string,
  moduleSettings: Module,
) => {
  if (!moduleSettings?.settings?.[clientId]?.[siteId]) {
    return {}
  }
  moduleSettings = moduleSettings.settings[clientId][siteId]

  const modules = {}
  for (const [module, settings] of Object.entries(moduleSettings)) {
    for (const [settingName, setting] of Object.entries(settings)) {
      if (!moduleList[module]) {
        // This is a setting for a module that was removed from the UI. Ignore it.
        continue
      }
      if (!modules[module]) {
        const blank = cloneDeep(moduleList[module])
        blank.settings = {}
        modules[module] = blank
      }
      modules[module].settings[settingName] = setting
    }
  }

  return modules
}

export const generateClientList = ({
  moduleList,
  allClients,
  allSites,
  nonProdSettings = {},
  prodSettings = {},
  sanityCheckClientLevelSettings = false,
}: {
  moduleList: ModuleMap
  allClients: {[key: string]: Client}
  allSites: {[key: string]: Site}
  nonProdSettings?: Setting
  prodSettings?: Setting
  sanityCheckClientLevelSettings?: boolean
}) => {
  const clients: Client[] = []
  for (const [clientId, { name, logoUrl, useClientOnlyUrl, defaultSiteUrl }] of Object.entries(allClients)) {
    const sites: Site[] = []
    const clientSettings = parseClientSettings(moduleList, clientId, prodSettings)

    for (const [siteId, { title, type, inNonProd, inProd, lastUpdated }] of Object.entries(allSites[clientId] || {})) {
      const nonProd = parseSiteSettings(moduleList, clientId, siteId, nonProdSettings)
      const prod = parseSiteSettings(moduleList, clientId, siteId, prodSettings)

      const site = new Site({
        id: siteId,
        clientId,
        title,
        type,
        nonProd: cloneDeep(nonProd),
        production: cloneDeep(prod),
        inNonProd,
        inProd,
        lastUpdated,
      })

      // Save the settings to the Site object. We leave out any client-inherited
      // settings because we can infer the inheritance from their lack of
      // presence in the Site object. We do save the client-inherited settings
      // to Site.nonProd because we need to prompt the user to update them if
      // the client defaults change.
      for (const module of Object.values(nonProd)) {
        if (!module.id) {
          continue
        }

        site[module.id] = module
        for (const setting of Object.keys(module.settings || {})) {
          if (module.settings?.[setting].source === 'client') {
            if (sanityCheckClientLevelSettings && !clientSettings[module.id].settings[setting]) {
              // TODO is it safe to re-publish the site level value automatically?
              sentryException(new Error(`Client-level setting ${setting} for ${clientId}/${siteId} does not have a client default stored in prod. This will cause the UI to show nothing stored, but nonprod may behave differently than prod`))
            }
            // This is a better way to remove props than the delete operator.
            // See psc-js/utils/objects.js for more.
            const { [setting]: deleted, ...sanitized } = module.settings
            module.settings = sanitized
          }
        }
      }

      sites.push(site)
    }

    const client = new Client({
      id: clientId,
      name,
      logoUrl,
      useClientOnlyUrl,
      defaultSiteUrl,
      sites: sites.sort(sortByProp('title')),
    })

    Object.assign(client, clientSettings)
    clients.push(client)
  }
  return clients
}

/**
 * Checks if the provided settings in subset and superset are identical.
 *
 * @param {*} options options object expecting the following keys and values
 * @param {string} options.type the type of the setting. Typically going to be
 *  'List'
 * @param {Array.<Object>} options.subset array of settings objects.
 *  @see formatSiteConfigFromService for example of object format
 * @param {Array.<Object>} options.superset array of settings objects. Same as
 *  options.subset
 *
 * @returns {boolean} true if the values in subset deeply equal the values in
 *  superset. Note that if a setting exists in superset that does not exist in
 *  subset this will return false.
 */
export const settingValuesAreSubset = ({
  type,
  subset,
  superset,
}: {
  type?: string
  subset: Array<Setting>
  superset: Array<Setting>
}) => {
  // Use lodash isEqual function if values are not List
  // Or if one of the values is undefined
  if (type !== 'List' || !(subset && superset)) {
    return isEqual(subset, superset)
  }

  if (subset.length !== superset.length) {
    return false
  }

  return subset.every(({ value: a }, index) => {
    const b = superset[index].value

    // If listItem value is not an object (e.g. text) return basic equality
    if (typeof a !== 'object') {
      return a === b
    }

    for (const key of Object.keys(a)) {
      // Note the optional chain
      if (isEqual(a[key].value, b[key]?.value)) {
        continue
      }

      // If this is supposed be a list and it's not, return false
      // Nested list arrays need to be checked if parent value is different
      return a[key].type === type && settingValuesAreSubset({ type, subset: a[key].value, superset: b[key]?.value })
    }

    return true
  })
}

// Returns true if the module is enabled in the Config service (this does not
// work for the flat file configs).
export const moduleEnabled = module => Boolean(module?.meta?.enabled)

// Gets a single site setting for either non-prod or prod (determined by which api is passed)
export const getSiteSetting = async (
  api,
  clientId: string,
  siteId: string,
  module: string,
  setting: string,
) => {
  let res
  try {
    res = await api.getSiteSetting({
      clientId,
      siteId,
      module,
      setting,
    })
  }
  catch (error) {
    if (isAxiosError(error) && error.response && error.response.status === 404) {
      return {}
    }
    throw error
  }

  const settingConfig = objectifyConfigs(res.data)

  const settingValue = settingConfig?.settings?.[clientId]?.[siteId]?.[module]?.[setting]?.value
  if (typeof settingValue === 'undefined') {
    throw new Error('Unexpected setting response object format: ' + JSON.stringify(settingConfig))
  }

  return settingValue
}
