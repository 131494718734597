import Vue from 'vue'
import { createStore } from 'vuex'
import api from './api.js'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.js'
import noCard from '../../announcements/noCard.json'
import noBank from '../../announcements/noBank.json'
import noBankOrCard from '../../announcements/noBankOrCard.json'
import noPayment from '../../announcements/noPayment.json'
import loginIssues from '../../announcements/loginIssues.json'
import { makeRouteKey } from '@grantstreet/psc-js/utils/routing.js'
import { sentryException } from '../sentry.js'
import { getFirstValidScheduleDateForClient } from '@grantstreet/psc-js/utils/date.js'
import { GSG_ENVIRONMENT } from '@grantstreet/psc-environment/environment.js'
import { useDefaultTranslation, i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import { logActionFactory } from '@grantstreet/psc-vue/utils/logging.js'
import { getPayableSource } from '@grantstreet/payables'
import { configState, configGetters } from '@grantstreet/psc-config'
import { user } from '@grantstreet/login'

let logDiagnosticsAction
let logRequestAction

// Do not import anything here that also imports ../router.js, because that will
// lead to a circular dependency and the store will be undefined in router.js.

const announcements = {
  noCard,
  noBank,
  noBankOrCard,
  noPayment,
  loginIssues,
}

const store = createStore({
  modules: {
    PayHub: {
      namespaced: true,

      state: {
        // TODO: Remember *all* properties on the state object need to be initialized
        // here!
        eWalletKey: 0,
        dataVaultToken: null,
        // E-Wallet JWT
        encodedJwt: null,
        authPromise: null,

        routes: [],

        // Set when there's an announcement to display
        hasAnnouncement: false,

        signedInToNonprod: {},

        locale: 'en',

        loginCallbackActions: [],

        // The following flags are set by the govhub installer.
        enableHeader: true,
        enableFooter: true,
        enableMyForms: true,
        enableHomePage: true,
        enableFloatingCart: true,
        showAnnouncements: true,
        enableMySettingsModification: true,
      },

      getters: {

        menuRoutes: state =>
          // Move route filtering here to account for all routes (both search &
          // redirect)
          state.routes
            .filter(route => route.meta?.linkInNav)
            .sort((a, b) => a.meta.navOrder - b.meta.navOrder),

        searchPages: (state, getters, rootState, rootGetters) =>
          configGetters.payableSources
            ?.filter(({ sourceType }) => sourceType !== 'redirect' && sourceType !== 'taxsys-navigation-link')
            // Could spread these props in but it's not bad having a list 🤷‍♀️
            ?.map(
              source => {
                const {
                  pageRoute,
                  sourceType,
                  payablesAdaptor,
                  icon,
                  searchInputs,
                  exampleImage,
                  exampleImageText,
                  exampleImageAltText,
                  pageImageTop,
                  dropdownImage,
                  itemName,
                  pageTitle,
                  pageSubtitle,
                  pageDescription,
                  navOrder,
                  displayType,
                  blindDisplayNameLabel,
                  blindDisplayNamePlaceholder,
                  expandComponentKey,
                  hideInProd,
                  generateUniqueId,
                  enableBulkAddToCart,
                  bulkAddToCartFileFormat,
                  bulkAddToCartMaxItems,
                  bulkAddToCartInformationalPacket,
                } = getters.localizePayableSource(source)
                return {
                  route: pageRoute,
                  searchType: sourceType,
                  payablesAdaptor,
                  icon,
                  searchInputs,
                  exampleImage,
                  exampleImageText,
                  exampleImageAltText,
                  pageImageTop,
                  // Key dropdownImage has been renamed pageImageLeft everywhere but
                  // the site setting (as part of the GovHub UI redesign)
                  pageImageLeft: dropdownImage,
                  itemName,
                  title: pageTitle,
                  subtitle: pageSubtitle,
                  description: pageDescription,
                  navOrder,
                  displayType,
                  blindDisplayNameLabel,
                  blindDisplayNamePlaceholder,
                  expandComponentKey,
                  hideInProd,
                  generateUniqueId,
                  enableBulkAddToCart,
                  bulkAddToCartFileFormat,
                  bulkAddToCartMaxItems,
                  bulkAddToCartInformationalPacket,
                }
              },
            ) || [],

        redirectLinks: (state, getters, rootState, rootGetters) =>
          configGetters.payableSources
            ?.filter(({ sourceType, redirectLink, tempTaxsysEnvLink }) =>
              (
                sourceType === 'redirect' &&
                redirectLink
              ) ||
              (
                sourceType === 'taxsys-navigation-link' &&
                tempTaxsysEnvLink?.[GSG_ENVIRONMENT === 'dev' ? 'test' : GSG_ENVIRONMENT]
              ),
            )
            ?.map(({
              tempTaxsysEnvLink,
              redirectLink,
              sourceType,
              pageTitle,
              icon,
              navOrder,
              displayType,
              displaySidebar,
              hideInProd,
              // TODO: Remove once the PSC-13137 LD flag is removed
              payablesAdaptor,
            }) => ({
              route: sourceType === 'taxsys-navigation-link'
                ? tempTaxsysEnvLink[GSG_ENVIRONMENT === 'dev' ? 'test' : GSG_ENVIRONMENT]
                : redirectLink,
              searchType: sourceType,
              title: pageTitle,
              icon,
              navOrder,
              displayType,
              displayInNavbar: displaySidebar,
              hideInProd,
              payablesAdaptor,
            })) || [],

        // Return true to allow a user to navigate away from PH when clicking a
        // redirect link. Return false to show a warning modal
        shouldAllowRedirect: (state, getters, rootState, rootGetters) => () =>
          user.loggedIn || rootGetters['Cart/items'].length === 0,

        locale: state => state.locale,
        isMultilingual: (state, getters, rootState) => Boolean(
          configState.config.payHub.additionalLanguages &&
          configState.config.payHub.additionalLanguages.length,
        ),
        prodUrl: (state, getters, rootState) => {
          const { client, site } = configState.config
          return `${window?.location?.origin}/${client}/${site}`
        },

        signedInToNonprod: state => state.signedInToNonprod,

        firstValidScheduleDateForClient: (state, getters, rootState) => getFirstValidScheduleDateForClient(configState.config.cart.timeZone),

        hasAnnouncement: state => state.hasAnnouncement,

        pexClientName: (state, getters, rootState) => {
          return configState.config.paymentExpress.clientName
        },

        cardAuthBillApperance: (state, getters, rootState) => {
          return configState.config.cart.temporaryAuthStatementDescriptionCards
        },

        // Whether to show a survey after checkout
        showReceiptSurvey: (state, getters, rootState) => configState.config.cart.showReceiptSurvey,
        // Whether to show a survey after scheduling/canceling a payment
        showScheduleCreationSurvey: (state, getters, rootState) => configState.config.schedPay.showScheduleCreationSurvey,
        showScheduleCancellationSurvey: (state, getters, rootState) => configState.config.schedPay.showScheduleCancellationSurvey,

        // Branding
        clientTitle: (state, getters, rootState) => configState.config.payHub?.clientTitle,
        clientLogo: (state, getters, rootState) => configState.config.payHub?.clientLogo,
        clientCover: (state, getters, rootState) => configState.config.payHub.landingPageImage,
        favicon: (state, getters, rootState) => configState.config.payHub?.favicon,

        // Jotform or Dev-Built Forms on /forms/$formID
        formConfigs: () => {
          const jotformConfigs = Array.isArray(configState.config.forms?.formConfigurations) ? configState.config.forms.formConfigurations : []
          const devBuiltConfigs = Array.isArray(configState.config.forms?.devBuiltFormConfigurations) ? configState.config.forms.devBuiltFormConfigurations : []
          jotformConfigs.forEach(obj => {
            obj.type = 'jotform'
          })
          devBuiltConfigs.forEach(obj => {
            obj.type = 'devBuilt'
          })
          const combinedFormConfigs = [...jotformConfigs, ...devBuiltConfigs]
          return combinedFormConfigs
        },

        contactEntity: (state, getters, rootState, rootGetters) => displayType => {
          const departmentTitle = (
            getPayableSource(displayType) ||
            configGetters.payableSources[0]
          )?.departmentDisplayName
          return (`${getters.clientTitle} ${departmentTitle?.[state.locale] || ''}`).trim()
        },

        // Initialization promises
        authPromise: state => {
          if (state.authPromise) {
            return state.authPromise
          }
          throw new Error('authPromise is not initialized')
        },

        // Gets the URL to the client website. Looks up the URL from the payable
        // source for the passed display type.
        clientUrl: (state, getters, rootState, rootGetters) => displayType => {
          const sources = configGetters.payableSources
          if (!sources.length) {
            sentryException('Cannot get client URL for site with zero payable sources')
            return
          }

          let source
          if (displayType) {
            source = getPayableSource(displayType)
            if (!source) {
              sentryException(`Cannot find client url: No payable source found for display type "${displayType}".`)
              return
            }
          }
          else {
            // No display type passed, so default to the first (and hopefully
            // only) payable source. We can remove this once we refactor
            // returnUrl to support multi-dept redirects.
            source = sources[0]
            if (sources.length > 1) {
              sentryException(`Cannot disambiguate client URL from multiple payable sources when no displayType is passed. Using source for display type "${source.displayType}".`)
            }
          }

          return source.clientWebsiteUrl
        },

        // XXX: We'll need to update this once we support multi-dept redirects
        returnUrl: (state, getters, rootState, rootGetters) => {
          const urls = rootGetters['Cart/urls']
          return (urls && urls.return) ||
            configState.config.payHub.returnHomeUrl || getters.clientUrl()
        },

        // Returns all applicable fee structures based on the passed items or
        // the cart items.
        translatedFeeKeys: (state, getters, rootState, rootGetters) => items => {
          items = items || rootGetters['Cart/cart']?.items
          return [...new Set(
            items.map(item => `translated-fee-keys.${item.payable.itemCategory.toLowerCase()}`)
              .filter(item => i18n.global.te(item)),
          )]
        },

        /**
         * Returns function that accepts a string argument 'pexDepartment' and
         *  searches for this department. If no department is found this will
         *  return undefined. If a department is found the payment limit object
         *  in the following form:
         *   {
         *     pexDepartment: 'CUBS',
         *     cardLimit: '500.00',
         *     bankLimit: '', // currently unused
         *     paymentLimitDelay: {
         *       en: '72 hours',
         *       es: '72 horas',
         *     },
         *   }
         * @returns { (s: string) => object | undefined }
         */
        getPaymentLimits: (state, getters, rootState, rootGetters) => pexDepartment => {
          const limits = configState.config.cart.paymentLimits || []
          let limit
          if (pexDepartment) {
            limit = limits.find(limit => limit?.pexDepartment === pexDepartment)
          }

          const sources = configGetters.payableSources

          // If there is only one payable source, it's a redirect, and there is
          // one payment limit, return that payment limit.
          // This is to support the limit-exceeded page where we want to show
          // the payment limit on redirect but don't have a payable to look up
          // the PEx department on.
          //
          // TODO PSC-3857 Remove this.
          // TODO: What happens when we have no limits and a multi-redirect site
          // like al-jefferson/permits?
          if (
            !limit &&
            sources?.length === 1 &&
            sources[0].sourceType === 'redirect' &&
            limits?.length === 1
          ) {
            limit = limits[0]
          }

          if (typeof limit?.paymentLimitDelay === 'string') {
            // The limit is a raw string (untranslated). This can go away once
            // PSC-4985 is in prod and we update existing payable sources to
            // use the translated version.
            limit.paymentLimitDelay = {
              en: limit.paymentLimitDelay,
              es: '',
            }
          }
          return limit
        },

        itemCategories: (state, getters, rootState) =>
          configState.config.cart.itemCategories || [],

        statementDescriptions: (state, getters) => itemCategory => {
          const configSource = getters.itemCategories.find(
            category => category.itemCategory === itemCategory,
          ) || {}

          return {
            cardPrimary: configSource.primaryAmountStatementDescriptionCards,
            cardFee: configSource.feeStatementDescriptionCards,
            bankPrimary: configSource.primaryAmountStatementDescriptionBanks,
            bankFee: configSource.feeStatementDescriptionBanks,
            paypalPrimary: configSource.primaryAmountStatementDescriptionPayPal,
          }
        },

        // Accepts a $route object and returns the URL we want to redirect the
        // user back to after they log out. If redirectHomeOnLogout is false,
        // returns undefined.
        logoutRedirectUrl: (state, getters) => ({ params: { client, site }, meta: { redirectHomeOnLogout } }) => {
          if (!redirectHomeOnLogout) {
            return
          }
          const redirect = `${window.location.origin}/${client}/${site ? site + '/' : ''}`
          const siteUsesHomepage = getters.siteUsesHomepage
          return redirect + (siteUsesHomepage ? '' : 'checkout')
        },

        localizePayableSource: () => ({
          itemName,
          blindDisplayNameLabel,
          blindDisplayNamePlaceholder,
          ...source
        }) => ({
          ...source,
          itemName: useDefaultTranslation(itemName, 'item.default'),
          blindDisplayNameLabel: useDefaultTranslation(blindDisplayNameLabel, 'item.default'),
          blindDisplayNamePlaceholder: useDefaultTranslation(blindDisplayNamePlaceholder, 'empty_string'),
        }),

        eBillingAutoloadPayablePath: (state) => state.loginCallbackActions
          .find(action => action.type === 'openEBilling')?.payablePath,
      },

      mutations: {
        setRoutes (state, routes) {
          routes.forEach(route => {
            route.meta = route.meta || {}
            // *Definitely* unique key used for v-fors
            route.meta.key = makeRouteKey(route)
          })
          Vue.set(state, 'routes', routes)
        },

        setDataVaultToken (state, dataVaultToken) {
          state.dataVaultToken = dataVaultToken
        },

        setSignedInToNonprod (state, { siteKey, val }) {
          state.signedInToNonprod[siteKey] = val
        },

        setEncodedJwt (state, encodedJwt) {
          state.encodedJwt = encodedJwt
        },

        // XXX Does this cause memory leaks?
        rerenderEWallet (state) {
          state.eWalletKey = state.eWalletKey + 1
        },

        setAuthPromise (state, promise) {
          Vue.set(state, 'authPromise', promise)
        },

        setLoginCallbackActions (state, actions) {
          state.loginCallbackActions = actions
        },

        // DO NOT use this generally. Use the action `setLocale`
        // This is only for the install script to set the locale from storage
        setLocaleLight (state, locale) {
          state.locale = locale
        },
      },

      actions: {
        // Dispatch this to update the flags used to install govhub. This should
        // only be called once - by the govhub installer.
        setInstallFlags ({ state }, flags) {
          for (const flag in flags) {
            state[flag] = flags[flag]
          }
        },

        // Sets the user's language preference
        async setLocale ({ state, dispatch }, { locale, updateUser = true }) {
          if (updateUser) {
            await dispatch('setUserProfile', { language: locale })
          }

          try {
            window.localStorage.setItem('payhubDefaultLocale', locale)
          }
          catch (error) {
            console.error('Cannot access local storage due to incognito window')
          }

          i18n.global.locale.value = locale
          EventBus.$emit('payhub.localeChanged', locale)
          state.locale = locale
        },

        async setUserPhone ({ dispatch }, phone) {
          return dispatch('setUserProfile', { phone })
        },

        /**
         * Update the user's name in their login service profile. This
         * accepts their input given/family name and then also creates
         * the "name" value used for display.
         */
        async setUserName ({ dispatch }, update) {
          return dispatch('setUserProfile', update)
        },

        async setContactPreference ({ dispatch }, contactPreference) {
          return dispatch('setUserProfile', { contactPreference })
        },

        /**
         * Set a user's profile fields all at once. Available fields
         * are:
         *
         * - givenName
         * - familyName
         * - phone
         * - contactPreference
         * - language
         *
         * This should be the preferred method of updating a user's
         * profile. Sending too many different updates at once can cause
         * sync issues in Okta's database (see PSC-12245).
         */
        async setUserProfile ({ state, getters, rootGetters }, profile) {
          if (!user.loggedIn) return

          const mapping = {
            givenName: {
              api: 'given_name',
              event: 'given_name',
            },
            familyName: {
              api: 'family_name',
              event: 'family_name',
            },
            phone: {
              api: 'phone',
              event: 'https://govhub.com/phone',
            },
            contactPreference: {
              api: 'contact_preference',
              event: 'contact_preference',
            },
            language: {
              api: 'language',
              event: 'https://govhub.com/language',
            },
          }
          const apiPayload = {}
          const eventPayload = {}

          for (const key in profile) {
            apiPayload[ mapping[key].api ] = profile[key]
            eventPayload[ mapping[key].event ] = profile[key]
          }

          // Also update the "name" field if the given/family names have
          // changed
          if (profile.givenName && profile.familyName) {
            apiPayload.name = eventPayload.name = `${profile.givenName} ${profile.familyName}`
          }

          // Unset the older names for these profile fields to make sure
          // we get the new value.
          if (profile.phone) {
            eventPayload['https://pay-hub.net/phone'] = undefined
          }
          if (profile.language) {
            eventPayload['https://pay-hub.net/language'] = undefined
          }

          const api = rootGetters['API/login']
          await api.updateUser(user.id, apiPayload)
          EventBus.$emit('payhub.userMetadataChanged', eventPayload)
        },

        // name - name of announcement to enable
        async setAnnouncement ({ state, rootGetters }, { name }) {
          const announcement = announcements[name]

          if (!announcement) {
            state.hasAnnouncement = false
            return
          }

          // This means that the config must be loaded
          if (name === 'loginIssues' && !configGetters.useLogin) {
            // This particular banner would be confusing on sites that don't
            // offer login in the first place.
            state.hasAnnouncement = false
            return
          }

          state.hasAnnouncement = true
        },

        // Logs diagnostic information to Kibana.
        // Depends on VueCookies and API/requestService being installed
        logDiagnostics: ({
          getters,
          rootState,
          rootGetters,
        }, data) => {
          if (!logDiagnosticsAction) {
            const api = rootGetters['API/requestService']
            if (!api) {
              console.error('Error: No request service API set')
              return
            }

            logDiagnosticsAction = logActionFactory({
              logData: api.diag.bind(api),
              isDiagnostic: true,
            })
          }
          const { client, site } = configState.config
          return logDiagnosticsAction({
            data,
            defaultClient: client,
            defaultSite: site,
            // Append the email, since the id is opaque
            appUser: user.email ? `${user.id} - ${user.email}` : user.id,
          })
        },

        // Depends on VueCookies and API/requestService being installed
        logRequest: ({
          getters,
          rootGetters,
        }, data) => {
          if (!logRequestAction) {
            const api = rootGetters['API/requestService']
            if (!api) {
              console.error('Error: No request service API set')
              return
            }

            logRequestAction = logActionFactory({
              logData: api.logRequest.bind(api),
              isDiagnostic: false,
            })
          }

          return logRequestAction({
            data,
            // Append the email, since the id is opaque
            appUser: user.email ? `${user.id} - ${user.email}` : user.id,
          })
        },

      },
    },
  },
})

store.registerModule('API', api)

export default store
