//
// Login Module
//
// The initialization here is a little bit complicated, because
// login has to handle "callbacks" after the OIDC login and logout.
// The callback is a full page load. Our initialization routine
// isn't flexible enough to handle that through vue-router, but
// we do avoid a full page reload.
//
// Additionally, @grantstreet/login provides "authPromise", which resolves
// after we've either loaded the user or determined that nobody is
// logged in.

import Vue from 'vue'
import getOidcSettings from './config.js'
import { sentryException } from './sentry.js'
import storage from '@grantstreet/psc-js/utils/safe-local-storage.js'
import User from './models/User.ts'
import ExternalLoginManager from './implementations/external.ts'
import FullLoginManager from './implementations/full.ts'

import {
  vuexOidcProcessSignInCallback,
  vuexOidcCreateUserManager,
  VuexOidcClientSettings,
} from 'vuex-oidc'

export type OidcClient = string
export type Bus = typeof Vue
export type UseLogin = () => boolean
export type GetClientInfo = () => {
  client: string
  site: string
  clientDisplay: string
  siteDisplay: string
  clientLogo: string
}
export type BaseRoute = string | undefined
export type LogAnalyticsEvent = (action: string, payload: {
  event_category?: string
  event_label?: string
  value?: string
}) => void

export type AuthPromise = Promise<void>

export type CallbackActionType = {
  type: string
  [key: string]: unknown
}

export type FullModeInstallOptions = {
  oidcClient: OidcClient
  bus: Bus
  useLogin: UseLogin
  getClientInfo: GetClientInfo
  baseRoute?: BaseRoute
  logAnalyticsEvent?: LogAnalyticsEvent
}

export type ExternalModeInstallOptions = {
  bus?: Bus
  handleLogin?: () => void
} & ({
  getAccessToken: () => string
  getExternalJwt?: never
} | {
  getAccessToken?: never
  getExternalJwt: () => Promise<string>
})

export type LoginFunctionParams = {
  signup?: boolean
  callbackAction?: CallbackActionType
  client?: string
  site?: string
  clientDisplay?: string
  siteDisplay?: string
  clientLogo?: string
}
export type LoginFunction = (params?: LoginFunctionParams) => void

export type LogoutFunction = (redirectUri?: string) => Promise<void>

export type GetPostLoginRedirectFunction = () => (string | undefined)

/**
 * An interface for managing standard login functionality. Each login manager
 * implementation should implement this interface.
 */
export type LoginManager = {
  authPromise: AuthPromise

  login: LoginFunction

  logout: LogoutFunction

  getPostLoginRedirect: GetPostLoginRedirectFunction
}

/**
 * A standard user object that can be imported from any package and contains the
 * current user's details (or no details if not logged in).
 */
export const user = new User()

/**
 * The LoginManager instance for the current app. This can be imported in an app
 * after installLogin has been called, and it will be set to the initialized
 * LoginManager instance.
 */
export let loginManager: LoginManager

export const postLoginRedirectKey = 'postLoginRedirect'

/**
 * Installs the login module using "full" mode (see ./implementations/full.ts)
 */
export const installFullLogin = (opts: FullModeInstallOptions) => {
  loginManager = new FullLoginManager(opts)
  return loginManager
}

/**
 * Installs the login module using "external" mode (see
 * ./implementations/external.ts)
 *
 * This accepts a getAccessToken function that returns the current access token
 * from the parent app.
 */
export const installExternalLogin = (opts: ExternalModeInstallOptions) => {
  loginManager = new ExternalLoginManager(opts)
  return loginManager
}

/**
 * Checks whether the current URL is a callback URL, and if so
 * finalizes the login callback process.
 */
export async function handleLoginCallback (
  oidcClient: OidcClient,
  baseRoute?: BaseRoute,
): Promise<undefined> {
  const isLoginCallback = /\/callback(?:[/#?]|$)/
  const isLogout = /\/logout(?:[/#?]|$)/

  const path = window.location.href

  if (isLoginCallback.test(path)) {
    // This is the callback in the main window
    try {
      const settings = getOidcSettings({
        baseRoute,
        oidcClient,
      }) as VuexOidcClientSettings

      // TODO: PSC-20491 - Remove these casts when this issue is resolved
      // https://github.com/perarnborg/vuex-oidc/issues/210
      const redirectPath = await vuexOidcProcessSignInCallback(settings) as unknown as string
      const oidcUserManager = vuexOidcCreateUserManager(settings)
      await oidcUserManager.getUser()

      // User just successfully logged in
      window.history.replaceState(null, document.title, redirectPath)
      return
    }
    catch (error) {
      console.error('Authentication error:', error)
      sentryException(error as Error)
      stashPostLoginRedirect('login-error')
      window.history.replaceState(null, document.title, '/')
      return
    }
  }
  // This is Auth0-specific cruft, which we need because it
  // doesn't do "end_session" by the OIDC book. (If it did,
  // vuex-oidc would handle this automatically.)
  if (isLogout.test(path)) {
    const url = sessionStorage.getItem('vuex_oidc_active_route') || '/'
    window.history.replaceState(null, document.title, url)
  }
}

function stashPostLoginRedirect (route: string) {
  storage.setItem(postLoginRedirectKey, route)
}
