export type ContactPreference = 'email' | 'sms'

export type GetAccessTokenFunction = () => (string | undefined)

/**
 * The default getAccessToken() function, which just returns undefined. This
 * exists so User.getAccessToken can always be safely called even if this is an
 * anonymous user without having to worry about optional chaining, like
 * User.getAccessToken?.().
 */
function defaultGetAccessToken () {
  return undefined
}

/**
 * A standard representation of a user (either logged in or not)
 */
export default class User {
  /**
   * The user's ID, e.g., 'okta|00u2cmxinfWkRKM321d7'. Always defined if the
   * user is logged in.
   */
  id?: string

  /**
   * The user's email address. Always be defined if the user is logged in.
   */
  email?: string

  /**
   * The user's full name
   */
  name?: string

  /**
   * The user's "first name," if available
   */
  givenName?: string

  /**
   * The user's "last name," if available
   */
  familyName?: string

  /**
   * The user's preferred method of contact
   */
  contactPreference?: ContactPreference

  /**
   * The user's phone number. May not be defined for all users.
   */
  phone?: string

  /**
   * The user's language preference, if available
   */
  language?: 'en' | 'es'

  /**
   * If this user is an admin, what client do they have permission to access?
   */
  adminClient?: string

  /**
   * A function that returns the user's auth token (or undefined if the user is
   * not logged in). This function should always be defined if the user is
   * logged in.
   *
   * This is a function so that it can be passed to API clients and always
   * return an up-to-date JWT (i.e., after token refreshes).
   */
  getAccessToken: GetAccessTokenFunction

  constructor (data?: Pick<User,
    'id' |
    'email' |
    'name' |
    'givenName' |
    'familyName' |
    'contactPreference' |
    'phone' |
    'language' |
    'adminClient' |
    'getAccessToken'
  >) {
    this.id = data?.id
    this.email = data?.email
    this.name = data?.name
    this.givenName = data?.givenName
    this.familyName = data?.familyName
    this.contactPreference = data?.contactPreference
    this.phone = data?.phone
    this.language = data?.language
    this.adminClient = data?.adminClient
    this.getAccessToken = data?.getAccessToken || defaultGetAccessToken
  }

  /**
   * Is the user currently logged in?
   */
  get loggedIn () {
    return this.id
      ? !this.id.match(/^anon\|/)
      : false
  }

  /**
   * Clears all of the fields, returning a user to its "anonymous" state.
   */
  reset () {
    this.id = undefined
    this.email = undefined
    this.name = undefined
    this.givenName = undefined
    this.familyName = undefined
    this.contactPreference = undefined
    this.phone = undefined
    this.language = undefined
    this.adminClient = undefined
    this.getAccessToken = defaultGetAccessToken
  }
}
